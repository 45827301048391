import Cookies from 'js-cookie';
import { IEntitlement } from '../interfaces/entitlement.interface';
import { Product } from '@legalshield/frontend-commons/dist/sdk/products';
import { Product as EProduct } from '@legalshield/frontend-commons/dist/sdk/entitlements';
import { GroupResource, PayType } from '@legalshield/frontend-commons/dist/sdk/groups';
import { getFriendlyProductNameFromEntitlement } from '../utils/getFriendlyName'

export class EntitlementPresenter {
  entitlement: IEntitlement;
  locale: string | null | undefined;

  constructor(entitlement: IEntitlement) {
    this.entitlement = entitlement;
    this.locale = Cookies.get('market') || '';
  }

  isNationalProduct(): boolean {
    const productBrochure: Product = this.entitlement?.productBrochure as Product;
    return productBrochure?.isNational || false;
  }

  productFriendlyName(): string {
    return getFriendlyProductNameFromEntitlement(this.entitlement);
  }

  entitlementFriendlyId(): string {
    const friendlyId = this.entitlement.friendlyId || '';
    const trimedFriendlyId = friendlyId.replace('dev-tools-', '');
    return trimedFriendlyId;
  }

  hasLaunch(): boolean {
    const product: EProduct = this.entitlement?.product as EProduct;
    return !!product.supplements?.Launch;
  }

  hasPayrollDeductGroup(): boolean {
    const group = this.entitlement?.groupInformation as GroupResource;
    return group ? group?.payType !== PayType.SELF_PAY : false;
  }

  getSupplementFriendlyName(supplementName: string): string {
    const productBrochure: Product = this.entitlement?.productBrochure as Product;
    const supplement = productBrochure.supplements?.find((sup) => sup.internalName === supplementName);
    let name = supplement?.friendlyNames?.find((friendlyName) => {
      const language = this.locale?.split('-')[0]?.toLowerCase();
      return friendlyName.locale?.toLowerCase() === (language || 'en');
    })?.name;
    if (!name) {
      name = supplement?.friendlyNames?.find((friendlyName) => friendlyName.locale?.toLowerCase() === 'en')?.name;
    }
    if (!name) {
      name = supplementName === 'Launch' ? string_table.HOME_LAUNCH_DEFAULT : '';
    }
    return name;
  }

  isLegal(): boolean {
    return this.entitlement.product.productFamily === 'Legal';
  }
}
