// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found-center {
  margin-left: auto;
  margin-right: auto;
  max-width: 424px;
  min-width: 300px;
  flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/PageNotFound/PageNotFound.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;AACF","sourcesContent":[".page-not-found-center {\n  margin-left: auto;\n  margin-right: auto;\n  max-width: 424px;\n  min-width: 300px;\n  flex-grow: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
