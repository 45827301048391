import { Container, Icon, Heading } from '@legalshield/adonis-ux-framework';
import React, { ReactElement } from 'react';
import cn from 'classnames';

export interface PlanControlProps {
  planTitle: string;
  onRedirect?: () => void;
  children?: ReactElement | ReactElement[];
  classNames?: string[];
  showExternalLink?: boolean;
}

export const PlanControl: React.FC<PlanControlProps> = ({
  planTitle,
  onRedirect,
  showExternalLink,
  children,
  classNames = [],
}) => {
  const containerClasses = cn('mx-n4', classNames);

  return (
    <>
      <Container flexbox flexDirection="row" background="none" classNames={containerClasses}>
        <div style={{ display: 'flex' }} className={'flex-sm-column'}>
          <div className={'mb-4'}>
            <Heading as="T16" text={planTitle} />
          </div>
          <div>{children}</div>
        </div>
        <div style={{ display: 'flex' }} className={'ml-auto align-items-center'}>
          {showExternalLink && <Icon name="nav_external_link" size="large" onClick={onRedirect} />}
        </div>
      </Container>
    </>
  );
};
