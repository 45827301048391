// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upgrade-banner {
  background-color: var(--p10);
  border-radius: 11px;
  color: var(--n900);
  display: flex;
  justify-content: center;
  padding: 20px;
}
.upgrade-banner__content {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}
.upgrade-banner__content__left {
  width: 100%;
}
.upgrade-banner__content__right {
  flex: 50px;
  flex-direction: row;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/Overview/UpgradeBanner/UpgradeBanner.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;AACF;AACE;EACE,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,OAAA;EACA,8BAAA;AACJ;AACI;EACE,WAAA;AACN;AACI;EACE,UAAA;EACA,mBAAA;EACA,yBAAA;AACN","sourcesContent":[".upgrade-banner {\n  background-color: var(--p10);\n  border-radius: 11px;\n  color: var(--n900);\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n\n  &__content {\n    align-items: baseline;\n    display: flex;\n    flex-direction: row;\n    flex: 1;\n    justify-content: space-between;\n\n    &__left {\n      width: 100%;\n    }\n    &__right {\n      flex: 50px;\n      flex-direction: row;\n      justify-content: flex-end;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
