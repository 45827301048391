import { Login } from '@legalshield/frontend-commons';
import { RequestParams } from '@legalshield/frontend-commons/dist/sdk/identities';

export const getPreferred = (objects: any[]): any => {
  if (!(objects?.length > 0)) {
    return null;
  }

  const preferredObject = objects.filter((object) => {
    return object.preferred;
  })[0];

  if (preferredObject) {
    return preferredObject;
  }

  return objects[0];
};

export function sortByPropAndPreferred(objects: any[], prop: string, ascending: boolean): any[] {
  let sortedObjects = ascending ? sortAscByProperty(objects, prop) : sortDescByProperty(objects, prop);
  sortedObjects = sortedObjects.sort((_objectA) => {
    return _objectA.preferred ? -1 : 1;
  });
  return sortedObjects;
}

export function sortAscByProperty(objects: any[], property: string): any[] {
  if (!objects) {
    return [];
  }
  return objects.sort((_objA, _objB) => {
    if (_objA[property] < _objB[property]) {
      return -1;
    }
    if (_objA[property] > _objB[property]) {
      return 1;
    }
    return 0;
  });
}

export function sortDescByProperty(objects: any[], property: string): any[] {
  if (!objects) {
    return [];
  }
  return objects.sort((_objA, _objB) => {
    if (_objA[property] < _objB[property]) {
      return 1;
    }
    if (_objA[property] > _objB[property]) {
      return -1;
    }
    return 0;
  });
}

export function camelize(str: string | undefined): string {
  if (!str) {
    return '';
  }
  return str
    .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function validateStatusResponse(): RequestParams {
  return {
    validateStatus: (status: number) => {
      if (status === 401) {
        window.location.href = Login.getLoginUrl();
        return false;
      }
      return status >= 200 && status < 300;
    },
    withCredentials: true,
  };
}

export async function waitInSeconds(seconds) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
