import { Login } from '@legalshield/frontend-commons/dist/utils';
import { CardType, PaymentMethodResource, RequestParams } from '@legalshield/frontend-commons/dist/sdk/payment-methods';

export const validateStatusResponse = (): RequestParams => {
  return {
    validateStatus: (status: number) => {
      if (status === 401) {
        window.location.href = Login.getLoginUrl();
        return false;
      }
      return status >= 200 && status < 300;
    },
    withCredentials: true,
  };
};

export const formatExactDate = (date: string | undefined) => {
  if (!date) return '';

  const [year, month, day] = date.split('-').map(Number);

  return `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}/${year}`;
};

export const isLegal = (productFamily?: string | null): boolean | undefined => {
  if (productFamily === undefined || productFamily === null) return;
  return productFamily === 'Legal';
};

export const isAssociate = (productFamily?: string | null): boolean | undefined => {
  if (productFamily === undefined || productFamily === null) return;
  return productFamily === 'Associate';
};

export const isBankAccount = (paymentMethod: PaymentMethodResource | undefined) => {
  if (!paymentMethod) return false;

  return (
    paymentMethod?.type?.toLowerCase() === 'bankaccount' &&
    (paymentMethod?.accountNumber || paymentMethod?.bankAccount?.accountNumber) != null
  );
};

export const formatCardType = (card: CardType | undefined) => {
  if (!card) return '';

  return card
    .toString()
    .toLowerCase()
    .split(' ')
    .map((str) => str?.charAt(0)?.toUpperCase() + str?.substring(1))
    .join(' ');
};

export const getLogo = (productFamily?: string | null): string => {
  let logo: string = 'idshield-logo.svg';

  if (isLegal(productFamily)) {
    logo = 'legalshield-logo.svg';
  }

  if (isAssociate(productFamily)) {
    logo = 'officebyls-color.svg';
  }

  return logo;
};

export const isPostalCodeCanadian = (postalCode: string | null | undefined): boolean => {
  // Return false if no postal code is provided
  if (!postalCode) return false;

  // Define the regex pattern for a Canadian postal code
  const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

  // Test the postal code against the pattern
  return canadianPostalCodePattern.test(postalCode);
};
