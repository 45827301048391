import React, { Fragment } from 'react';
import { Badge, Button, Card, Container, Text } from '@legalshield/adonis-ux-framework';
import { FactorResponse, FactorType } from '@legalshield/frontend-commons/dist/sdk/factors';

export interface FactorDisplayProps {
  item: FactorResponse;
  onPromote: () => void;
  onDelete: () => void;
}

export const FactorDisplay: React.FC<FactorDisplayProps> = ({ onPromote, onDelete, item }: FactorDisplayProps) => {
  const tabName =
    item.type === FactorType.SMS ? string_table.MFA_FACTORS_SMS_TITLE : string_table.MFA_FACTORS_AUTHENTICATOR_TITLE;

  return (
    <Card cardHeight="100%">
      <Card.Content>
        <Card.Title>{tabName}</Card.Title>
        <Container flexbox flexDirection="column">
          <Text textSize="small" text={item.name} />
          <Container flexbox justifyContent="space-between" alignItems="center" flexWrap="wrap" classNames={['pt-3']}>
            <Container flexbox alignItems="baseline" classNames={['py-3']} style={{ gap: '3px' }}>
              {item.verified ? (
                <Badge text={string_table.MFA_FACTORS_DISPLAY_VERIFIED} variant="success" bold={false} />
              ) : (
                <Badge text={string_table.MFA_FACTORS_DISPLAY_UNVERIFIED} variant="important" bold={false} />
              )}
              {item.primary ? (
                <Badge
                  text={string_table.MFA_FACTORS_DISPLAY_PRIMARY}
                  variant="success"
                  bold={false}
                  classNames={['ml-2', 'pt-3']}
                />
              ) : (
                <Fragment />
              )}
            </Container>
            <Container flexbox flexWrap="wrap" justifyContent="flex-end" style={{ gap: '6px' }}>
              {!item.primary && item.verified && (
                <Button
                  label={string_table.MFA_FACTORS_DISPLAY_MAKEPRIMARY}
                  onClick={onPromote}
                  variant="tertiary"
                  textSize="small"
                />
              )}
              <Button label={string_table.DELETE} onClick={onDelete} destructive variant="tertiary" textSize="small" />
            </Container>
          </Container>
        </Container>
      </Card.Content>
    </Card>
  );
};
