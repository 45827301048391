import { Api as SubscriptionsApi } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { ISubscriptionResource, setSubscriptionProduct } from './utils/subscription-utils';
import { useQuery } from '@tanstack/react-query';

const subscriptionsApi = new SubscriptionsApi();

const identityId = pplsi?.authNPayload?.sub;

export const getSubscriptionBySubscriptionId = async (subscriptionId: string): Promise<ISubscriptionResource> => {
  if (!subscriptionId) {
    return null;
  }

  const { data: subscription } = await subscriptionsApi.v1.subscriptionsGetSubscription(identityId, subscriptionId, {
    withCredentials: true,
  });
  return getSubscriptionWithProduct(subscription as ISubscriptionResource);
};

const getSubscriptionWithProduct = async (subscription: ISubscriptionResource) => {
  try {
    await setSubscriptionProduct(subscription);
    return subscription;
  } catch (error) {
    return Promise.resolve(subscription);
  }
};

export const useGetSubscriptionBySubscriptionId = (subscriptionId: string) => {
  return useQuery({
    queryFn: () => getSubscriptionBySubscriptionId(subscriptionId),
    queryKey: ['subscription', subscriptionId],
  });
};
