import React, { FC, useMemo, useState } from 'react';
import { Button, Container, Icon, Modal, Text } from '@legalshield/adonis-ux-framework';
import URLs from '../../utils/url';
import Config from '../../config';
import IframeContainer from './IframeContainer';
import { useUrlParamManager } from '../../hooks/useUrlParamManager';
import { useBannerAlert } from '../../components/Alerts/Banner/context/BannerAlertContext';
import { Layout } from '../Layout/Layout';
import { waitInSeconds } from '../../utils/utils';

const useSearchParams = () => {
  const { getParameter } = useUrlParamManager();
  return useMemo(() => {
    const params = {
      addPayment: getParameter('add_payment')?.toUpperCase(),
      callbackUrl: getParameter('callback_url'),
      intent: getParameter('intent')?.toUpperCase(),
      memberId: getParameter('member'),
      redirectUrl: getParameter('redirect'),
      resource_id: getParameter('resource_id'),
      subscriptionId: getParameter('subscriptionId'),
    };
    return params;
  }, [window.location.search]);
};

interface WalletProps {
  showBreadcrumbs?: boolean;
}

const Wallet: FC<WalletProps> = ({ showBreadcrumbs = true }) => {
  const { bannerAlertDispatch } = useBannerAlert();

  const { addPayment, callbackUrl, memberId, redirectUrl, resource_id, subscriptionId } = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [paymentIframeHeight, setPaymentIframeHeight] = useState<number>(0);

  let paymentsUrl = URLs.getPPLSIAppURL(Config.currentEnv.apps.paymentsFrame);

  if (redirectUrl) {
    paymentsUrl = redirectUrl;
  }

  if (addPayment !== undefined) {
    paymentsUrl = `${paymentsUrl}?add_payment=${addPayment}&resource_id=${resource_id}&member=${memberId}&callback_url=${callbackUrl}`;
  }
  const [headerText, setHeaderText] = useState(string_table.WALLET_HEADER);
  const [showSecurityText, setShowSecurityText] = useState(true);

  const onMsg = async (msg: MessageEvent) => {
    const data = msg.data;
    if (typeof data === 'string') {
      const parts = data.split(':');
      const strippedData = parts[0];
      switch (strippedData) {
        case 'PaymentIframeHeight':
          const height = parts[1];
          setPaymentIframeHeight(parseInt(height, 10));
          break;
        case 'PaymentAdded':
          bannerAlertDispatch({ message: string_table.ALERT_SUCCESS, severity: 'success', type: 'DISPLAY' });
          if (callbackUrl) {
            const paymentMethodId = parts[1];
            await waitInSeconds(5).then(() => {
              window.location.href = callbackUrl.concat(
                `?resource_id=${paymentMethodId}&subscriptionId=${subscriptionId}`,
              );
            });
          }
          break;
        case 'PaymentUpdateFailed':
          console.error(string_table.ALERT_ERROR);
          bannerAlertDispatch({ message: string_table.ALERT_ERROR, severity: 'error', type: 'DISPLAY' });
          break;
        case 'PaymentMethodsLoadFailure':
          console.error(string_table.WALLET_PAYMENT_ERROR);
          break;
        case 'PaymentMethodsListUnRendering':
          setHeaderText(string_table.WALLET_ADD_NEW);
          setShowSecurityText(false);
          break;
        case 'PaymentMethodsListRendering':
          setHeaderText(string_table.WALLET_HEADER);
          setShowSecurityText(true);
          break;
        case 'JWTExpired':
          const isExpired = parts[1] === 'true';
          if (isExpired) {
            setShowModal(true);
          }
          break;
        case 'PaymentMethodCooldown':
          bannerAlertDispatch({
            alertType: 'snackbar',
            message: string_table.WALLET_PAYMENT_COOLDOWN,
            severity: 'error',
            title: string_table.WALLET_PAYMENT_COOLDOWN_TITLE,
            type: 'DISPLAY',
          });
          break;
      }
    }
  };

  const registerIframeEvents = () => {
    window.addEventListener('message', onMsg, false);
  };

  const SessionModal = () => {
    const loginBaseUrl = URLs.getPPLSIAppURL(Config.currentEnv.apps.login);
    const returnUrl = `${paymentsUrl}?intent=add`;

    return (
      <Modal position="center">
        <Modal.Title>{string_table.WALLET_JWT_EXPIRED_TITLE}</Modal.Title>
        <Text classNames={['p-2']} text={string_table.WALLET_JWT_EXPIRED_TEXT} />
        <Modal.Actions>
          <Button
            variant="primary"
            label={string_table.WALLET_JWT_EXPIRED_BUTTON}
            onClick={() => window.location.replace(`${loginBaseUrl}?app=accounts&path=wallet?redirect=${returnUrl}`)}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  const removeRedirectParam = () => {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has('redirect')) {
      currentUrl.searchParams.delete('redirect');
      window.history.replaceState(null, '', currentUrl.toString());
    }
  };

  const handleOnIframeLoad = () => {
    registerIframeEvents();
    removeRedirectParam();
  };

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: 'Wallet', link: '/wallet' },
  ];

  return (
    <Layout
      crumbs={showBreadcrumbs && crumbs}
      title={string_table.WALLET_HEADER}
      subtitle={
        <Container flexbox alignItems="center" classNames={['pt-3']}>
          <Icon name="object_lock" />
          <Text id="security-text" text={string_table.WALLET_SECURITY_TEXT} textSize="large" classNames={['pl-2']} />
        </Container>
      }
    >
      <Container>
        {showModal ? (
          <SessionModal />
        ) : (
          <>
            <IframeContainer
              src={paymentsUrl}
              title={'payments'}
              name={'payments'}
              contentHeight={paymentIframeHeight}
              onLoad={handleOnIframeLoad}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Wallet;
