import axios from 'axios';
import cookies from 'js-cookie';

import {
  IAcceptInvitationRequest,
  IInvitationResponseVerification,
  IInvitationsResponse,
  InvitationAcceptRequestSecurityQuestion,
  IResponseData,
} from '../interfaces/invitation.interface';
// import { IInvitationsResponse, IResponseData } from '../interfaces/response.data.interface';
import { ACTIVATE_ACCEPT_ENDPOINT, ACTIVATE_RESEND_ENDPOINT, ACTIVATE_SUBMIT_ENDPOINT } from '../config';

const acceptInvitation = async ({
  invitationId,
  securityQuestions,
  code,
  version,
}: {
  invitationId: string;
  securityQuestions?: InvitationAcceptRequestSecurityQuestion[];
  code: string;
  version: number;
}): Promise<IInvitationResponseVerification> => {
  const body: IAcceptInvitationRequest = {
    identityId: JSON.parse(cookies.get('info_user') || '{}').sub,
    verification: { code },
  };
  if (securityQuestions) {
    body.verification.securityQuestions = securityQuestions;
  }
  const axiosConfig = {
    headers: { 'If-Match': version },
    params: { invitationId },
  };

  const response = await axios.post(`${ACTIVATE_ACCEPT_ENDPOINT}`, body, axiosConfig);

  return response.data;
};

const resendCode = async (email: string): Promise<IInvitationsResponse> => {
  const body = { email: encodeURIComponent(email) };

  const response = await axios.post<IInvitationsResponse>(`${ACTIVATE_RESEND_ENDPOINT}`, body);

  return response.data;
};

const submitCode = async (verificationCode: string): Promise<IResponseData> => {
  const body = { code: verificationCode };
  const response = await axios.post<IResponseData>(`${ACTIVATE_SUBMIT_ENDPOINT}`, body);

  return response.data;
};

export default {
  acceptInvitation,
  resendCode,
  submitCode,
};
