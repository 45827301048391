// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-upgrade-modal__title, .confirm-upgrade-modal__text {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/ConfirmUpgradeModal/ConfirmUpgradeModal.scss"],"names":[],"mappings":"AACE;EAEE,kBAAA;AADJ","sourcesContent":[".confirm-upgrade-modal {\n  &__title,\n  &__text {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
