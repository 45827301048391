// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pwd-help-box-placement {
  right: 0px;
  top: 20px;
  min-width: fit-content;
}

.pwd-help {
  white-space: nowrap;
  font-size: var(--font-small);
}

.pwd-help li {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.pwd-help .rules {
  padding-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Controls/PasswordUtil/PasswordHelp.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,SAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,4BAAA;AACF;;AAEA;EACE,qBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".pwd-help-box-placement {\n  right: 0px;\n  top: 20px;\n  min-width: fit-content;\n}\n\n.pwd-help {\n  white-space: nowrap;\n  font-size: var(--font-small);\n}\n\n.pwd-help li {\n  list-style-type: none;\n  display: flex;\n  align-items: center;\n}\n\n.pwd-help .rules {\n  padding-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
