import React, { FC } from 'react';
import { Card, Container, Tabs } from '@legalshield/adonis-ux-framework';

import Subscriptions from './Subscriptions/Subscriptions';
import Wallet from '../Wallet/Wallet';
import { ISubscriptionResource } from './hooks/utils/subscription-utils';
import { Layout } from '../Layout/Layout';
import { PrecancelModalProvider } from './PrecancelModal/PrecancelModalContext';
import { SubscriptionCard } from './SubscriptionCard';
import { useGetSubscriptionsByIdentityId } from './hooks/useSubscriptions';
import { useLocation, useNavigate } from 'react-router-dom';
import ProvisioningBanner from '../Overview/ProvisioningBanner/ProvisioningBanner';

export interface PaymentsProps {
  newSubscriptionsUi: boolean;
}

const Payments: FC<PaymentsProps> = ({ newSubscriptionsUi }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: subscriptions, isLoading: subscriptionsLoading } = useGetSubscriptionsByIdentityId();
  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.PAYMENT_TITLE, link: '/payments' },
  ];

  // Derive the current tab value from the URL
  const lastPath = location.pathname.split('/').pop();
  const currentTab = lastPath !== 'payments' ? lastPath : null;

  const handleTabChange = (value) => {
    navigate(`/payments/${value}`);
  };

  return (
    <PrecancelModalProvider>
      <Layout
        crumbs={crumbs}
        title={string_table.PAYMENT_TITLE}
        subtitle={string_table.SUBSCRIPTION_DESCRIPTION}
        bannerElement={<ProvisioningBanner />}
      >
        {newSubscriptionsUi && (
            <Card>
              <Card.Content classNames={['p-4']}>
                <Tabs.Root
                  value={currentTab || 'subscriptions'}
                  onValueChange={handleTabChange}
                  variant="bar"
                  classNames={['pt-6']}
                  stretch={true}
                >
                  <Tabs.List aria-label="Select a tab">
                    <Tabs.Trigger value="subscriptions">{string_table.SUBSCRIPTION_HEADING}</Tabs.Trigger>
                    <Tabs.Trigger value="wallet">Wallet</Tabs.Trigger>
                  </Tabs.List>
                  <Tabs.Content value="subscriptions">
                    <Subscriptions subscriptions={subscriptions} isLoading={subscriptionsLoading} />
                  </Tabs.Content>
                  <Tabs.Content value="wallet">
                    <Wallet showBreadcrumbs={false} />
                  </Tabs.Content>
                </Tabs.Root>
              </Card.Content>
            </Card>
        )}
        {!newSubscriptionsUi && (
          <Container classNames={['pt-5']}>
            {subscriptions?.map((s: ISubscriptionResource) => (
              <div key={s?.id}>
                <SubscriptionCard subscription={s} />
              </div>
            ))}
          </Container>
        )}
      </Layout>
    </PrecancelModalProvider>
  );
};

export default Payments;
