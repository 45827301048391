import React, { FC, useEffect, useState } from 'react';
import { Card, Container, Divider, IFields, useForm, useLoader } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

import RealmUtility from '../../../utils/realm';
import { ButtonBar } from '../../Controls/ButtonBar';
import { Layout } from '../../Layout/Layout';
import { PasswordForm } from '../../Controls/PasswordUtil/PasswordForm';
import { useGetIdentity, useUpdatePassword } from '../../../hooks/useIdentity';

export const Password: FC = () => {
  const passwordFields = PasswordForm.passwordFields;
  passwordFields.password.label = string_table.PWFORM_NEWPASSWORD;
  const { data: sData } = useGetIdentity();
  const [isGroupAdmin] = useState(RealmUtility.isGroupAdmin());
  const [isPartner] = useState(RealmUtility.isPartner());

  const loader = useLoader();
  const navigate = useNavigate();

  const isNewPassword = (isPartner || isGroupAdmin) && !sData?.hasPassword;
  const formFields: Record<string, IFields> = isNewPassword
    ? passwordFields
    : {
        existing: {
          errorMessage: string_table.SECURITY_EXISITING_REQUIRED,
          label: string_table.SECURITY_EXISTING,
          required: true,
          type: 'password',
          value: '',
        },
        ...passwordFields,
      };
  const [formValues, formObject] = useForm(formFields, null);

  const { password, existing } = formValues;

  const { mutate: pwMutate, isSuccess, isError, data } = useUpdatePassword(sData?.version);

  const changePassword = () => {
    pwMutate({ currentPassword: existing?.value, newPassword: password.value });
  };

  const onNavigateBack = (message?: string, isError?: boolean): void => {
    if (message) {
      if (isError) {
        loader.Error(message);
      } else {
        loader.Success(message);
      }
    }
    navigate('/security');
  };

  useEffect(() => {
    if (isError || data?.status >= 400) {
      onNavigateBack(string_table.ALERT_ERROR, true);
      return;
    }
    if (isSuccess) {
      onNavigateBack(string_table.ALERT_SUCCESS, false);
    }
  }, [onNavigateBack, isSuccess, isError, data]);

  if (pplsi.authNPayload.act && pplsi.authNPayload.act.sub) {
    return <p>Not available in impersonation mode</p>;
  }

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.SECURITY_TITLE, link: '/security' },
    { label: string_table.PASSWORD_TITLE, link: '/security/password' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.SECURITY_TITLE} subtitle={string_table.SECURITY_DESCRIPTION}>
      <Divider classNames={['py-4']} />
      <div className="password_holder">
        <Card>
          <Card.Content>
            <Card.Title>{string_table.SECURITY_PASSWORD}</Card.Title>
            <Container classNames={'px-4'}>{formObject.renderForm()}</Container>
            <ButtonBar
              id="pswrdBtnBar"
              onCancel={() => onNavigateBack()}
              onSave={() => changePassword()}
              disableSave={!formObject.isValid}
            />
          </Card.Content>
        </Card>
      </div>
    </Layout>
  );
};
