import React, { forwardRef } from 'react';
import { BadgeVariant, Icon, Text } from '@legalshield/adonis-ux-framework';
import './BadgeIconControl.scss';
import { TextWeight } from '@legalshield/adonis-ux-framework/dist/components/atoms/text/Text';
import cn from 'classnames';

export interface BadgeIconControlProps extends Omit<React.HTMLProps<HTMLDivElement>, 'css'> {
  bold?: boolean | undefined;
  circle?: boolean | undefined;
  classNames?: cn.Argument | undefined;
  icon: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  small?: boolean | undefined;
  text: string;
  textWeight?: TextWeight | undefined;
  variant?: BadgeVariant | undefined;
}

const BadgeIconControl: React.ForwardRefRenderFunction<HTMLDivElement, BadgeIconControlProps> = (
  {
    bold = true,
    circle = true,
    classNames = [],
    icon,
    onClick,
    small = false,
    text,
    textWeight = 'normal',
    variant = 'default',
    ...props
  },
  ref,
) => {
  const classBadge = 'lsux-badge';
  const cnBadge = cn(
    {
      [`${classBadge}`]: true,
      [`badge-icon--${variant}`]: variant == 'important',
      [`${classBadge}--${variant}--bold`]: variant == 'primary' && bold,
      [`${classBadge}--${variant}--subtle`]: variant == 'primary' && !bold,
      [`${classBadge}--circle`]: circle,
      [`${classBadge}--square`]: !circle,
    },
    classNames,
  );

  return (
    <div
      ref={ref}
      style={{
        alignItems: 'center',
        border: '0',
        cursor: onClick ? 'pointer' : 'auto',
        flexDirection: 'row',
        ...props.style,
      }}
      className={cnBadge}
      onClick={onClick}
    >
      <Icon size={small ? 'small' : 'medium'} name={icon} color="N00" classNames={['mr-2']} />
      <Text
        textSize={small ? 'small' : 'medium'}
        textWeight={textWeight}
        style={{ color: '#ffffff' }}
        as="span"
        text={text}
      />
    </div>
  );
};

export default forwardRef(BadgeIconControl);
