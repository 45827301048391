import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  GridCol,
  GridRow,
  Heading,
  Link,
  Text,
  useForm,
} from '@legalshield/adonis-ux-framework';
import RealmUtility from '../../../utils/realm';
import { Identities, Factors } from '@legalshield/frontend-commons';
import { useCreateFactors, useVerifyFactors } from '../../../hooks/useIdentity';
import './AddAuthenticatorFactor.scss';

interface AddAuthenticatorFactorProps {
  loader: any;
  factorAddedSuccessfully: () => void;
  factorAddingCanceled: (id: string) => void;
  factorsLength: number | undefined;
}

const AddAuthenticatorFactor: React.FC<AddAuthenticatorFactorProps> = ({
  loader,
  factorAddedSuccessfully,
  factorAddingCanceled,
  factorsLength,
}: AddAuthenticatorFactorProps) => {
  const [factor, setFactor] = useState<Factors.FactorCreateResponse>({ id: '' } as Factors.FactorCreateResponse);
  const [isToggleManualEntry, setIsToggleManualEntry] = useState(false);

  const {
    mutateAsync: mutateAsyncAdd,
    isLoading: isLoadingAdd,
    isSuccess: isSuccessAdd,
    isError: isErrorAdd,
  } = useCreateFactors();

  const {
    mutateAsync: mutateAsyncVerify,
    isLoading: isLoadingVerify,
    isSuccess: isSuccessVerify,
    isError: isErrorVerify,
    error: errorVerify,
  } = useVerifyFactors();

  useEffect(() => {
    if (isLoadingAdd) {
      loader.Loading();
    }
    if (isErrorAdd) {
      loader.Error(string_table.MFA_ADD_ERRORADD);
    }
    if (isSuccessAdd) {
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAdd, isLoadingAdd, isSuccessAdd]);

  useEffect(() => {
    if (isLoadingVerify) {
      loader.Loading();
    }
    if (isErrorVerify) {
      if (errorVerify.message.includes('422')) {
        loader.Error(string_table.MFA_ADD_AUTH_INVALID);
      } else {
        loader.Error(string_table.ALERT_ERROR);
      }
    }
    if (isSuccessVerify) {
      loader.Blank();
      factorAddedSuccessfully();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessVerify, isLoadingVerify, isErrorVerify, errorVerify]);

  const addFactor = async () => {
    const data = {
      name: `${authNPayload.ual || ''} ${Math.trunc(Date.now() / 1000)}`,
      primary: factorsLength === 0,
      realm: RealmUtility.getRealm() as Identities.Realm,
      type: Factors.FactorType.GOOGLE,
    } as Factors.FactorCreateRequest;

    const result = await mutateAsyncAdd(data);
    setFactor(result as Factors.FactorCreateResponse);
  };

  const verify = async () => {
    await mutateAsyncVerify({ code: { code: codeFormValues['code'].value }, factorId: factor.id });
  };

  const toggleManualEntry = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsToggleManualEntry(!isToggleManualEntry);
  };

  useEffect(() => {
    addFactor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [codeFormValues, codeFormObject] = useForm(
    {
      code: {
        errorMessage: string_table.MFA_ADD_CODE_INVALID,
        label: string_table.MFA_ADD_CODE,
        placeHolder: string_table.MFA_ADD_CODE,
        regEx: /^\d{6}$/,
        required: true,
        type: 'text',
        value: '',
      },
    },
    null,
  );

  return (
    <Container>
      <Heading text={string_table.MFA_ADD_AUTH_TITLE} as="T20" />
      <Text as="p" textSize="small" classNames={['my-3']} text={string_table.MFA_ADD_AUTH_DESCRIPTION} />
      <Card>
        <Card.Title>{`1. ${string_table.MFA_ADD_AUTH_SCAN_STEP} `}</Card.Title>
        <Card.Content>
          <Grid>
            <GridRow variant="plain">
              <GridCol>
                {factor.qrImage && (
                  <img
                    style={{ height: '255px', width: '255px' }}
                    src={factor.qrImage}
                    alt={`${string_table.MFA_ADD_AUTH_QR_CODE_ALT} ${factor?.entryKey || ''}`}
                  />
                )}
                <Text as="p" text={string_table.MFA_ADD_AUTH_SCAN_DESC} />
                <Divider classNames={['mt-4']} />
                <Link onClick={toggleManualEntry} text={string_table.MFA_ADD_AUTH_MANUAL} />
                {isToggleManualEntry ? (
                  <Container classNames={['my-4']}>
                    <Text as="p" text={string_table.MFA_ADD_AUTH_MANUAL_INSTRUCTIONS1} />
                    <Text
                      as="p"
                      textWeight="bold"
                      text={string_table.MFA_ADD_AUTH_MANUAL_INSTRUCTIONS2.replace('%key%', factor?.entryKey || '')}
                    />
                    <Text as="p" text={string_table.MFA_ADD_AUTH_MANUAL_INSTRUCTIONS3} />
                  </Container>
                ) : (
                  <Fragment />
                )}
              </GridCol>
            </GridRow>
          </Grid>
        </Card.Content>
      </Card>
      <Card>
        <Card.Title>{`2. ${string_table.MFA_ADD_AUTH_VERIFY_STEP} `}</Card.Title>
        <Card.Content>
          <Text
            as="p"
            classNames={['mx-6']}
            text={string_table.MFA_ADD_AUTH_VERIFY_DESC.replace('%name%', factor.name)}
          />
          <Grid>
            <GridRow variant="plain">
              <GridCol>
                {codeFormObject.renderForm()}
                <Button
                  classNames={['my-4']}
                  label={string_table.VERIFY}
                  onClick={() => verify()}
                  stretch
                  variant="primary"
                  disabled={!codeFormObject.isValid}
                />
              </GridCol>
            </GridRow>
          </Grid>
        </Card.Content>
      </Card>
      <Container flexbox justifyContent="flex-end" classNames={['my-4', 'mr-8']}>
        <Button
          variant="secondary"
          label={string_table.CANCEL}
          onClick={() => {
            factorAddingCanceled(factor.id);
          }}
        />
      </Container>
    </Container>
  );
};

export default AddAuthenticatorFactor;
