import { FactorResource, MultifactorSettingResource } from '@legalshield/frontend-commons/dist/sdk/identities';
import { Factors, Identities, SDKHeaders } from '@legalshield/frontend-commons';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { validateStatusResponse } from '../utils/utils';

const identitiesApi = new Identities.Api();
const factorsApi = new Factors.Api();

const QUERY_IDENTITY = 'query-identity';
const QUERY_MULTIFACTOR = 'query-multifactor';

export interface MultifactorResponse {
  factors: FactorResource[];
  settings: MultifactorSettingResource;
}

function defaultOptions(queryClient: QueryClient, queryToInvalidate?: string) {
  return {
    onError: (err) => {
      console.log(`error ${err}`);
    },
    onSettled: () => {
      if (queryToInvalidate) {
        queryClient.invalidateQueries([queryToInvalidate]);
      }
    },
  };
}

async function fetchFactorsAndSettings(): Promise<MultifactorResponse> {
  const { data: factors } = await factorsApi.v1.factorsGetFactors(pplsi.authNPayload.sub, validateStatusResponse());
  const { data: settings } = await identitiesApi.v1.multifactorGetMultifactorType(
    pplsi.authNPayload.sub,
    validateStatusResponse(),
  );

  return { factors: orderFactors(factors), settings } as MultifactorResponse;
}

export const useGetFactors = () => {
  return useQuery([QUERY_MULTIFACTOR], fetchFactorsAndSettings, defaultOptions(useQueryClient()));
};

export const useGetIdentity = () => {
  return useQuery(
    [QUERY_IDENTITY],
    async () => (await identitiesApi.v1.identitiesFindByIdentityId(authNPayload.sub)).data,
  );
};

export const useUpdateMultifactorSettings = () => {
  return useMutation(
    ['updateMultifactorSettings'],
    async (payload: MultifactorSettingResource) => {
      const { data: settings } = await identitiesApi.v1.multifactorUpdateMultifactorType(
        authNPayload.sub,
        payload,
        validateStatusResponse(),
      );
      return settings;
    },
    defaultOptions(useQueryClient(), QUERY_MULTIFACTOR),
  );
};

export const useCreateFactors = () => {
  return useMutation(
    async (payload: Factors.FactorCreateRequest) => {
      return (await factorsApi.v1.factorsCreateFactor(pplsi.authNPayload.sub, payload, validateStatusResponse())).data;
    },
    defaultOptions(useQueryClient(), QUERY_MULTIFACTOR),
  );
};

export const useVerifyFactors = () => {
  return useMutation(
    ['verifyFactors'],
    async (payload: any) => {
      return factorsApi.v1.factorsVerifyFactor(
        pplsi.authNPayload.sub,
        payload.factorId,
        { value: payload.code.code },
        validateStatusResponse(),
      );
    },
    defaultOptions(useQueryClient(), QUERY_MULTIFACTOR),
  );
};

export const useSendCode = () => {
  return useMutation(
    ['sendCode'],
    async (factorId: string) => {
      return factorsApi.v1.factorsSendCode(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    defaultOptions(useQueryClient()),
  );
};

export const useDeleteFactor = () => {
  return useMutation(
    ['deleteFactor'],
    async (factorId: string) => {
      return factorsApi.v1.factorsDeleteFactor(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    defaultOptions(useQueryClient(), QUERY_MULTIFACTOR),
  );
};

export const usePromoteFactor = () => {
  return useMutation(
    ['promoteFactor'],
    async (factorId: string) => {
      return factorsApi.v1.factorsSetPrimary(pplsi.authNPayload.sub, factorId, validateStatusResponse());
    },
    defaultOptions(useQueryClient(), QUERY_MULTIFACTOR),
  );
};

export const useUpdatePassword = (version: number) => {
  return useMutation(
    ['updatePassword'],
    async (updatePassRequest: Identities.UpdatePasswordRequest) =>
      await identitiesApi.v2.passwordsUpdatePassword(
        authNPayload.sub,
        updatePassRequest,
        SDKHeaders.getVersionConfig(version),
      ),
    defaultOptions(useQueryClient(), QUERY_IDENTITY),
  );
};

export const useAddEmail = () => {
  return useMutation(
    ['addEmail'],
    async (addEmailRequest: any) =>
      (
        await identitiesApi.v1.emailAddEmail(
          authNPayload.sub,
          addEmailRequest.req,
          SDKHeaders.getVersionConfig(addEmailRequest.version),
        )
      ).data,
    defaultOptions(useQueryClient(), QUERY_IDENTITY),
  );
};

export const useRemoveEmail = () => {
  return useMutation(
    ['removeEmail'],
    async (removeEmailRequest: any) =>
      (
        await identitiesApi.v1.emailRemoveEmail(
          authNPayload.sub,
          removeEmailRequest.req,
          SDKHeaders.getVersionConfig(removeEmailRequest.version),
        )
      ).data,
    defaultOptions(useQueryClient(), QUERY_IDENTITY),
  );
};

function orderFactors(factors: FactorResource[]): FactorResource[] {
  if (!factors) {
    return [] as FactorResource[];
  }
  return factors.sort((x: FactorResource, y: FactorResource) => {
    return Number(y.primary) - Number(x.primary);
  });
}
