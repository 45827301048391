import Config from '../config';

/**
 * Return a string url dynamically create with the current domain. Finds the current environment and creates the url.
 * @param {string}  app - the single name of the app, becoming the subdomain.
 * @param {string=} path - An optional param to create a path, needs to be complete with initial forward slash
 */
function getPPLSIAppURL(app: string, path?: string): string {
  let res = `https://${app}.${Config.currentEnv.domain}`;
  if (path) {
    res = res + path;
  }
  return res;
}

/**
 * Return a string url for the legalshield domain. Finds the current environment and creates the url.
 * @param {string}  app - the single name of the app, becoming the subdomain.
 * @param {string=} path - An optional param to create a path, needs to be complete with initial forward slash
 */
function getLSAppUrl(app: string, path?: string): string {
  const lsEnv = Config.getLsEnv();
  let res = `https://${app}.`;
  switch (lsEnv) {
    case 'dev':
      res = res + 'dev-legalshield.com';
      break;
    case 'uat':
      res = res + 'uat-legalshield.com';
      break;
    case 'prod':
      res = res + 'legalshield.com';
      break;
  }
  if (path) {
    res = res + path;
  }
  return res;
}

export default {
  getLSAppUrl,
  getPPLSIAppURL,
};
