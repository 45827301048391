import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Container } from '@legalshield/adonis-ux-framework';

import './AvailablePlanSkeleton.scss';

export const UpgradePlanSkeleton: FC = () => (
  <Container flexbox flexDirection="row" classNames={['upgrade-plan__container', 'mt-5', 'mb-4']}>
    <div className="lsux-card p-5 upgrade-plan__row-width">
      <Skeleton width={40} height={20} />
      <Skeleton count={3} className="mt-5" />
    </div>
    <div className="lsux-card p-5 upgrade-plan__row-width">
      <Skeleton width={40} height={20} />
      <Skeleton count={3} className="mt-5" />
    </div>
  </Container>
);
