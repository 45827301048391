import { BillingPeriod } from '@legalshield/frontend-commons/dist/sdk/subscriptions';

const BILLING_FREQUENCY: Record<BillingPeriod, string> = {
  ANNUAL: string_table.BILLING_ANNUAL,
  BIANNUAL: string_table.BILLING_BIANNUAL,
  DAILY: string_table.BILLING_DAILY,
  MONTHLY: string_table.BILLING_MONTHLY,
  PAY_PERIOD: string_table.BILLING_PAY_PERIOD,
  QUARTERLY: string_table.BILLING_QUARTERLY,
  WEEKLY: string_table.BILLING_WEEKLY,
} as const;

const BILLING_FREQUENCY_INTERVALS: Record<BillingPeriod, string> = {
  ANNUAL: string_table.BILLING_ANNUAL_INTERVAL,
  BIANNUAL: string_table.BILLING_BIANNUAL_INTERVAL,
  DAILY: string_table.BILLING_DAILY_INTERVAL,
  MONTHLY: string_table.BILLING_MONTHLY_INTERVAL,
  PAY_PERIOD: string_table.BILLING_PAY_PERIOD_INTERVAL,
  QUARTERLY: string_table.BILLING_QUARTERLY_INTERVAL,
  WEEKLY: string_table.BILLING_WEEKLY_INTERVAL,
} as const;

export const getBillingFrequency = (billingPeriod: BillingPeriod, interval = false): string | null => {
  if (interval) {
    return BILLING_FREQUENCY_INTERVALS[billingPeriod] || null;
  }
  return BILLING_FREQUENCY[billingPeriod] || null;
};
