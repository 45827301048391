// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upgrade-plan__save_percent {
  font-size: 16px;
  color: var(--color-brand-secondary-BETA-burnt-sienna-600, #dc733d);
  line-height: 22px;
}
.upgrade-plan__save_percent_text {
  font-size: 16px;
  color: var(--text-secondary, #737373);
  line-height: 22px;
}
.upgrade-plan__footer {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
}
.upgrade-plan__tabs_list {
  width: 35% !important;
  margin: auto !important;
}
.upgrade-plan__subscription_button {
  width: 40%;
  margin: 40px auto 0px;
}
.upgrade-plan__compare_plans_button {
  width: 20%;
  margin: 40px auto 0px;
}
.upgrade-plan__desktop {
  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: center;
}
.upgrade-plan__available_plan {
  flex: 1;
}
.upgrade-plan__footer_text {
  color: var(--text-secondary, #737373);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.upgrade-plan__footer_number {
  color: black;
}

@media (max-width: 768px) {
  .upgrade-plan__mobile {
    display: flex;
    flex-direction: column;
  }
  .subscription_button__mobile {
    width: 100%;
  }
  .compare_plans__mobile {
    width: 100%;
  }
  .upgrade-plan__tabs_list__mobile {
    width: 40% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/UpgradePlan/UpgradePlan.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kEAAA;EACA,iBAAA;AAAJ;AAEE;EACE,eAAA;EACA,qCAAA;EACA,iBAAA;AAAJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,qBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,UAAA;EACA,qBAAA;AAAJ;AAEE;EACE,UAAA;EACA,qBAAA;AAAJ;AAEE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,OAAA;AAAJ;AAEE;EACE,qCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE;IACE,aAAA;IACA,sBAAA;EADF;EAGA;IACE,WAAA;EADF;EAGA;IACE,WAAA;EADF;EAGA;IACE,qBAAA;EADF;AACF","sourcesContent":[".upgrade-plan {\n  &__save_percent {\n    font-size: 16px;\n    color: var(--color-brand-secondary-BETA-burnt-sienna-600, #dc733d);\n    line-height: 22px;\n  }\n  &__save_percent_text {\n    font-size: 16px;\n    color: var(--text-secondary, #737373);\n    line-height: 22px;\n  }\n  &__footer {\n    font-size: 10px;\n    font-weight: 400;\n    line-height: 12px;\n    text-align: center;\n  }\n  &__tabs_list {\n    width: 35% !important;\n    margin: auto !important;\n  }\n  &__subscription_button {\n    width: 40%;\n    margin: 40px auto 0px;\n  }\n  &__compare_plans_button {\n    width: 20%;\n    margin: 40px auto 0px;\n  }\n  &__desktop {\n    display: flex;\n    gap: 30px;\n    flex-direction: row;\n    justify-content: center;\n  }\n  &__available_plan {\n    flex: 1;\n  }\n  &__footer_text {\n    color: var(--text-secondary, #737373);\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n  }\n  &__footer_number {\n    color: black;\n  }\n}\n\n@media (max-width: 768px) {\n  .upgrade-plan__mobile {\n    display: flex;\n    flex-direction: column;\n  }\n  .subscription_button__mobile {\n    width: 100%;\n  }\n  .compare_plans__mobile {\n    width: 100%;\n  }\n  .upgrade-plan__tabs_list__mobile {\n    width: 40% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
