import { useThemisAnalytics } from '../hooks/useThemisAnalytics';

const viewBeginUpgradeGtmEvent = () => {
  // trigger GA4 recommended event for view begin upgrade
  useThemisAnalytics().triggerEvent('view_begin_upgrade');
};

const beginUpgradeGtmEvent = () => {
  // trigger GA4 recommended begin upgrade event
  useThemisAnalytics().triggerEvent('begin_upgrade');
};

const viewUpgradeConfirmationGtmEvent = () => {
  // trigger GA4 recommended view upgrade confirmation event
  useThemisAnalytics().triggerEvent('view_upgrade_confirmation');
};

const upgradeThumbsUpGtmEvent = () => {
  // trigger GA4 recommended thumbs up event
  useThemisAnalytics().triggerEvent('upgrade_thumbs_up');
}

const upgradeThumbsDownGtmEvent = () => {
  // trigger GA4 recommended thumbs up event
  useThemisAnalytics().triggerEvent('upgrade_thumbs_down');
}

const viewEntitlementGtmEvent = (title: string) => {
  // trigger GA4 recommended event for view entitlement
  useThemisAnalytics().triggerCustomEvent('click_button', {title: title, action_type: 'view_entitlement'});
}

export default {
  viewBeginUpgradeGtmEvent,
  beginUpgradeGtmEvent,
  viewUpgradeConfirmationGtmEvent,
  upgradeThumbsUpGtmEvent,
  upgradeThumbsDownGtmEvent,
  viewEntitlementGtmEvent
};
