// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-icon, .badge-icon--important {
  flex-direction: row;
  align-items: center;
  color: black;
  border: 0;
}
.badge-icon--important {
  background: var(--r200);
}`, "",{"version":3,"sources":["webpack://./src/components/Controls/BadgeIcon/BadgeIconControl.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;AACF;AAAE;EAEE,uBAAA;AACJ","sourcesContent":[".badge-icon {\n  flex-direction: row;\n  align-items: center;\n  color: black;\n  border: 0;\n  &--important {\n    @extend .badge-icon;\n    background: var(--r200);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
