import React, { Fragment } from 'react';
import { GridCol, Text } from '@legalshield/adonis-ux-framework';
import { ProductBenefit } from '@legalshield/frontend-commons/dist/sdk/eligibilities';

type ProductBenefitsProps = {
  productBenefits: ProductBenefit | null | undefined;
};

export const ProductBenefits = ({ productBenefits }: ProductBenefitsProps) => {
  if (productBenefits == undefined) return <Fragment />;
  return (
    <>
      {productBenefits?.benefits?.map((benefit, index) => (
        <GridCol key={index}>
          <Text id="product-benefit" text={`*${benefit.friendlyName}`} />
        </GridCol>
      ))}
    </>
  );
};
