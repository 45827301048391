import React, { FC } from 'react';
import { Container, Modal, Button, Text, Image, ModalProps, Heading, Spinner } from '@legalshield/adonis-ux-framework';

import './ConfirmUpgradeModal.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import errorIcon from './errorIcon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import successIcon from './successIcon.svg';
import { useLaunchDarkly } from '../../../hooks/useLaunchDarkly';
import PaymentDropdown from '../PaymentDropdown';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { populateTemplateString } from '../../../utils/stringFormat';

export type ModalStateType = 'confirm' | 'success' | 'error';
interface ConfirmUpgradeModalProps extends ModalProps {
  modalState: ModalStateType;
  title: string;
  plan?: string;
  monthlyPrice: string;
  memberNumber: string;
  loadingTitle: string;
  isLoading: boolean;
  onCancelPress: () => void;
  onConfirmPress: () => void;
  onDonePress: () => void;
  onLikePress: () => void;
  onDislikePress: () => void;
  onErrorOkPress: () => void;
  tier: string;
  nextBillOn: string;
  subscription: ISubscriptionResource;
}

const ConfirmUpgradeModal: FC<ConfirmUpgradeModalProps> = (
  {
    modalState = 'confirm',
    title,
    plan,
    monthlyPrice,
    memberNumber,
    loadingTitle,
    isLoading,
    onCancelPress,
    onConfirmPress,
    onDonePress,
    onLikePress,
    onDislikePress,
    onErrorOkPress,
    tier,
    nextBillOn,
    subscription,
  },
  ...props
) => {
  const handleCancel = () => {
    if (!isLoading) {
      onCancelPress();
    }
  };

  const showCloseButton = !isLoading;
  const isConfirmationModal = modalState === 'success';
  const { collectFeedback } = useLaunchDarkly();
  const confirmationModalText = populateTemplateString(string_table.UPGRADE_CONFIRMATION_MODAL_TEXT, { tier });

  return (
    <Modal
      closeButton={showCloseButton}
      closeFunction={isConfirmationModal ? onDonePress : handleCancel}
      position="top"
      maxWidth="688px"
      contentProps={{
        onEscapeKeyDown: showCloseButton && !isConfirmationModal ? onCancelPress : (e) => e.preventDefault(),
        onInteractOutside: showCloseButton && !isConfirmationModal ? onCancelPress : (e) => e.preventDefault(),
      }}
      classNames={['confirm-upgrade-modal']}
      {...props}
    >
      {modalState === 'error' && (
        <>
          <Image src={errorIcon} alt="errorIcon" height={32} width={32} />
          <Container background="none" flexbox flexDirection={'column'} alignItems={'center'}>
            <Heading
              as="T20"
              text={string_table.UPGRADE_CONFIRMATION_ERROR_TEXT}
              classNames={['confirm-upgrade-modal__title', 'mt-4']}
            />
            <Text
              text={string_table.UPGRADE_CONFIRMATION_MODAL_ERROR_TEXT}
              textSize="large"
              classNames={['confirm-upgrade-modal__text', 'mb-4', 'mt-4']}
            />
          </Container>
          <Modal.Actions>
            <Button
              iconLeft="comm_phone"
              iconSize="medium"
              label={string_table.CALL_CUSTOMER_CARE}
              onClick={() => window.open('tel:1-800-654-7757', '_self')}
              stretch
              variant="primary"
            />
            <Button variant="secondary" label={string_table.DONE} textSize="medium" onClick={onErrorOkPress} />
          </Modal.Actions>
        </>
      )}
      {modalState === 'success' && (
        <>
          <Image src={successIcon} alt="successIcon" height={32} width={32} />
          <Container background="none" flexbox flexDirection={'column'} alignItems={'center'}>
            <Heading
              as="T20"
              text={string_table.UPGRADE_CONFIRMATION_SUCCESS_TEXT}
              classNames={['confirm-upgrade-modal__title', 'mt-4']}
            />
            <Text
              text="Your receipt may take up to 72 hours to process." // TODO: Update this copy
              textSize="large"
              classNames={['confirm-upgrade-modal__text', 'mb-4', 'mt-4']}
            />
            {collectFeedback && (
              <Text
                text={string_table.FEEDBACK_TEXT}
                textSize="large"
                textWeight="semibold"
                classNames={['confirm-upgrade-modal__text', 'mt-4']}
              />
            )}
          </Container>
          <Modal.Actions>
            {!collectFeedback && (
              <Button variant="secondary" label={string_table.DONE} textSize="medium" onClick={onDonePress} />
            )}
            {collectFeedback && (
              <Container
                background="none"
                flexbox
                flexDirection="row"
                justifyContent="space-around"
                classNames={'mt-4'}
              >
                <Button
                  variant="secondary"
                  label={string_table.FEEDBACK_DID_NOT_LIKE}
                  textSize="medium"
                  onClick={onDislikePress}
                  iconRight="interface_thumbs_down"
                  destructive={true}
                  iconColor="R100"
                />
                <Button
                  variant="primary"
                  label={string_table.FEEDBACK_LIKE}
                  textSize="medium"
                  onClick={onLikePress}
                  iconRight="interface_thumbs_up"
                />
              </Container>
            )}
          </Modal.Actions>
        </>
      )}
      {modalState === 'confirm' && (
        <>
          {isLoading ? (
            <>
              <Modal.Title>{loadingTitle}</Modal.Title>
              <Container flexbox flexDirection="column" alignItems="center">
                <Spinner spinnerSize="large" />
              </Container>
            </>
          ) : (
            <>
              <Modal.Title>{title}</Modal.Title>
              <Container flexbox flexDirection="row" alignItems="center">
                <Container flexbox flexDirection="column">
                  <Text textSize="large" text={confirmationModalText} />
                  <Container flexbox flexDirection="row" classNames={['mt-4']}>
                    <Text
                      textSize="large"
                      text={string_table.UPGRADE_CONFIRMATION_MODAL_PLAN}
                      textWeight="semibold"
                      classNames={['mr-2']}
                    />
                    <Text textSize="large" text={plan} />
                  </Container>
                  <Container flexbox flexDirection="row">
                    <Text
                      textSize="large"
                      text={string_table.UPGRADE_CONFIRMATION_MODAL_MONTHLY_PRICE}
                      textWeight="semibold"
                      classNames={['mr-2']}
                    />
                    <Text textSize="large" text={`$${monthlyPrice}/mo`} />
                  </Container>
                  <Container flexbox flexDirection="row">
                    <Text
                      textSize="large"
                      text={string_table.UPGRADE_CONFIRMATION_MODAL_NEXT_BILL_DATE}
                      textWeight="semibold"
                      classNames={['mr-2']}
                    />
                    <Text textSize="large" text={`${nextBillOn}`} />
                  </Container>
                  <Container flexbox flexDirection="row">
                    <Text
                      textSize="large"
                      text={string_table.UPGRADE_CONFIRMATION_MODAL_MEMBER_NUMBER}
                      textWeight="semibold"
                      classNames={['mr-2']}
                    />
                    <Text textSize="large" text={memberNumber} />
                  </Container>
                </Container>
                <Container flexbox flexDirection="column">
                  <Text
                    textSize="large"
                    text={string_table.UPGRADE_CHANGE_PAYMENT_METHOD_TEXT}
                    classNames={['mb-4', 'mt-6']}
                    textWeight="semibold"
                  />
                  <PaymentDropdown
                    key={subscription?.paymentMethodId}
                    subscription={subscription}
                    switchPaymentDuringUpgrade
                  />
                </Container>
              </Container>
              <Container flexbox flexDirection="row">
                <Text
                  classNames={['mr-2', 'mt-4']}
                  italic={true}
                  text={string_table.UPGRADE_EXISTING_SUPPLEMENTS_NO_CHANGE}
                  textSize="medium"
                  textWeight="normal"
                />
              </Container>
            </>
          )}
          <Modal.Actions>
            <Button
              variant="primary"
              label={string_table.UPGRADE_CONFIRMATION_CTA_TEXT}
              textSize="medium"
              disabled={isLoading}
              onClick={onConfirmPress}
              widthLong="33%"
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export default ConfirmUpgradeModal;
