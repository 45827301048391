import { EmailResource } from '@legalshield/frontend-commons/dist/sdk/profiles';

export class EmailPresenter {
  emails: EmailResource[];
  version: number;

  constructor(emails: EmailResource[], version: number) {
    this.emails = emails || [];
    this.version = version;
  }
}
