import React, { Fragment, ReactElement } from 'react';
import { PasswordHelp } from './PasswordHelp';
import { IFields } from '@legalshield/adonis-ux-framework';

const ValidatePassword = (value: string) => {
  return {
    caseValid: /[a-z]/.test(value) && /[A-Z]/.test(value),
    lengthValid: value.length >= 8,
    numberValid: /\d+/g.test(value),
    specialValid: /[~`!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?]/g.test(value),
  };
};

const onPasswordValidate = (value: string) => {
  const p = ValidatePassword(value);

  return p.lengthValid && p.caseValid && p.specialValid && p.numberValid;
};

const onPasswordRender = (inputValues: Record<string, IFields>): ReactElement => {
  const currentValue: IFields = inputValues['password'];

  if (currentValue.hasFocus) {
    const p = ValidatePassword(inputValues['password'].value);
    return (
      <PasswordHelp
        lengthValid={p.lengthValid}
        caseValid={p.caseValid}
        numberValid={p.numberValid}
        specialValid={p.specialValid}
      />
    );
  } else {
    return <Fragment />;
  }
};
const passwordFields: Record<string, IFields> = {
  password: {
    errorMessage: string_table.PWFORM_ERROR_STRENGTH,
    label: string_table.PWFORM_PASSWORD,
    onPostRender: onPasswordRender,
    onValidate: onPasswordValidate,
    required: true,
    type: 'password',
    value: '',
  },
};

export const PasswordForm = {
  passwordFields,
};
