import { SDKHeaders } from '@legalshield/frontend-commons/dist/utils';
import { Api as SubscriptionsApi, UpdatePaymentMethod } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { validateStatusResponse } from '../utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubscriptionBySubscriptionId } from './useSubscription';
import { useBannerAlert } from '../../Alerts/Banner/context/BannerAlertContext';

const subscriptionsApi = new SubscriptionsApi();

const identityId = pplsi?.authNPayload?.sub;

export const useHandleUpdatePaymentMethod = (subscriptionId: string) => {
  const queryClient = useQueryClient();
  const { bannerAlertDispatch } = useBannerAlert();

  return useMutation(
    ['update-payment-method', subscriptionId],
    async (payload: UpdatePaymentMethod) => {
      const subscription = await getSubscriptionBySubscriptionId(subscriptionId);

      if (!subscription) {
        throw new Error(`Subscription with ID ${subscriptionId} doesn't exist`);
      }

      return subscriptionsApi.v1.subscriptionsUpdatePaymentMethod(
        identityId,
        subscriptionId,
        payload,
        SDKHeaders.getVersionConfig(subscription?.version, true, validateStatusResponse()),
      );
    },
    {
      onError: (err: any) => {
        console.error(`Error updating payment method: ${err}`);
        bannerAlertDispatch({ message: string_table.ALERT_ERROR, severity: 'error', type: 'DISPLAY' });
      },
      onSuccess: () => {
        bannerAlertDispatch({ message: string_table.ALERT_SUCCESS, severity: 'success', type: 'DISPLAY' });
        queryClient.invalidateQueries(['subscriptions']);
        queryClient.invalidateQueries(['subscription', subscriptionId]);
      },
    },
  );
};
