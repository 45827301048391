// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memberships-list {
  max-height: 410px;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}
.memberships-list__alert {
  transition: all 0.3s ease-in;
  max-height: 0;
  opacity: 0;
}
.memberships-list__alert--show {
  max-height: unset;
  opacity: 1;
}
.memberships-list--expanded {
  max-height: 100%;
}

.product-card-content {
  padding-bottom: 0rem;
}

.product-container, .product-container--associate, .product-container--legal, .product-container--identity {
  margin: -1rem -1rem auto -1rem;
  padding: 1.5rem;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.product-container--identity {
  background-color: #d9dffc;
}
.product-container--legal {
  background-color: var(--p10);
}
.product-container--associate {
  background-color: #ecf8fb;
}

.product-launch-button p {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Overview/Memberships/Memberships.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,gCAAA;AACF;AACE;EACE,4BAAA;EACA,aAAA;EACA,UAAA;AACJ;AACI;EACE,iBAAA;EACA,UAAA;AACN;AAGE;EACE,gBAAA;AADJ;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,8BAAA;EACA,eAAA;EACA,4BAAA;EACA,6BAAA;AAFF;AAGE;EAEE,yBAAA;AAFJ;AAIE;EAEE,4BAAA;AAHJ;AAKE;EAEE,yBAAA;AAJJ;;AAQA;EACE,UAAA;AALF","sourcesContent":[".memberships-list {\n  max-height: 410px;\n  overflow: hidden;\n  transition: all 0.6s ease-in-out;\n\n  &__alert {\n    transition: all 0.3s ease-in;\n    max-height: 0;\n    opacity: 0;\n\n    &--show {\n      max-height: unset;\n      opacity: 1;\n    }\n  }\n\n  &--expanded {\n    max-height: 100%;\n  }\n}\n\n.product-card-content {\n  padding-bottom: 0rem;\n}\n\n.product-container {\n  margin: -1rem -1rem auto -1rem;\n  padding: 1.5rem;\n  border-top-left-radius: 11px;\n  border-top-right-radius: 11px;\n  &--identity {\n    @extend .product-container;\n    background-color: #d9dffc;\n  }\n  &--legal {\n    @extend .product-container;\n    background-color: var(--p10);\n  }\n  &--associate {\n    @extend .product-container;\n    background-color: #ecf8fb;\n  }\n}\n\n.product-launch-button p {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
