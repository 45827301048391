import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Container, Heading, Text, useForm } from '@legalshield/adonis-ux-framework';
import RealmUtility from '../../../utils/realm';

import { Identities, Factors } from '@legalshield/frontend-commons';
import { useCreateFactors, useSendCode, useVerifyFactors } from '../../../hooks/useIdentity';

interface AddSMSFactorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader: any;
  factorAddedSuccessfully: () => void;
  factorAddingCanceled: (id: string) => void;
  factorsLength: number | undefined;
}

const AddSMSFactor: React.FC<AddSMSFactorProps> = ({
  loader,
  factorAddedSuccessfully,
  factorAddingCanceled,
  factorsLength,
}: AddSMSFactorProps) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [factor, setFactor] = useState<Factors.FactorCreateResponse | null>({} as Factors.FactorCreateResponse);

  const {
    mutateAsync: mutateAsyncAdd,
    isLoading: isLoadingAdd,
    isSuccess: isSuccessAdd,
    isError: isErrorAdd,
  } = useCreateFactors();
  const {
    mutate: mutateVerify,
    isLoading: isLoadingVerify,
    isSuccess: isSuccessVerify,
    isError: isErrorVerify,
    error: errorVerify,
  } = useVerifyFactors();
  const {
    mutate: mutateSendCode,
    isLoading: isLoadingSendCode,
    isSuccess: isSuccessSendCode,
    isError: isErrorSendCode,
  } = useSendCode();

  useEffect(() => {
    if (isLoadingAdd || isLoadingVerify || isLoadingSendCode) {
      loader.Loading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAdd, isLoadingVerify, isLoadingSendCode]);

  useEffect(() => {
    if (isErrorAdd) {
      loader.Error(string_table.MFA_ADD_ERRORADD);
    }
    if (isSuccessAdd) {
      setActiveTab(2);
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd, isErrorAdd]);

  useEffect(() => {
    if (isErrorVerify) {
      if (errorVerify.message.includes('422')) {
        loader.Error(string_table.MFA_ADD_SMS_INVALID);
      } else {
        loader.Error(string_table.ALERT_ERROR);
      }
    }
    if (isSuccessVerify) {
      loader.Blank();
      factorAddedSuccessfully();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorVerify, isErrorVerify, isSuccessVerify]);

  useEffect(() => {
    if (isSuccessSendCode) {
      loader.Success(string_table.MFA_ADD_SMS_RESEND_SUCCESS);
    }
    if (isErrorSendCode) {
      loader.Error(string_table.MFA_ADD_SMS_RESEND_ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorSendCode, isSuccessSendCode]);

  const addFactor = async () => {
    const phoneValue = phoneFormValues['phoneNumber'].value;
    const name = `${string_table.MFA_ADD_SMS_PHONE_ENDS_WITH} ${phoneValue.slice(
      phoneValue.length - 4,
      phoneValue.length,
    )}`;
    const data: Factors.FactorCreateRequest = {
      name: name,
      phoneNumber: phoneValue,
      primary: factorsLength === 0,
      realm: RealmUtility.getRealm() as Identities.Realm,
      type: Factors.FactorType.SMS,
    };
    const result = await mutateAsyncAdd(data);
    setFactor(result as Factors.FactorCreateResponse);
  };

  const verify = async () => {
    await mutateVerify({ code: { code: codeFormValues['code'].value }, factorId: factor.id });
  };

  const resendCode = async (e: React.MouseEvent) => {
    e.preventDefault();
    mutateSendCode(factor.id);
  };

  const nextClicked = () => {
    if (activeTab === 1) {
      addFactor();
    } else if (activeTab === 2) {
      verify();
    }
  };

  const [phoneFormValues, phoneFormObject] = useForm(
    {
      phoneNumber: {
        autoComplete: 'tel-local',
        errorMessage: string_table.MFA_ADD_SMS_PHONENUMBER_INVALID,
        format: '###-###-####',
        inputMode: 'tel',
        label: string_table.MFA_ADD_SMS_PHONENUMBER,
        placeHolder: string_table.MFA_ADD_SMS_PHONENUMBER,
        regEx: /^\d{3}[-]\d{3}[-]\d{4}$/,
        required: true,
        type: 'tel',
        value: '',
      },
    },
    null,
  );

  const [codeFormValues, codeFormObject] = useForm(
    {
      code: {
        autoComplete: 'one-time-code',
        errorMessage: string_table.MFA_ADD_CODE_INVALID,
        inputMode: 'numeric',
        label: string_table.MFA_ADD_CODE,
        placeHolder: string_table.MFA_ADD_CODE,
        regEx: /^\d{6}$/,
        required: true,
        type: 'text',
        value: '',
      },
    },
    null,
  );

  return (
    <Container>
      <Heading text={string_table.MFA_ADD_SMS_TITLE} as="T20" classNames={['mb-4']} />
      <Accordion.Root type="single" value={activeTab === 2 ? 'item-2' : 'item-1'}>
        <Card classNames={['mb-4']}>
          <Card.Content>
            <Accordion.Item value="item-1" classNames={['mt-4']} border={false}>
              <Accordion.Trigger title={`1. ${string_table.MFA_ADD_SMS_PHONENUMBER_STEP} `} />
              <Accordion.Content>
                <>
                  {phoneFormObject.renderForm()}
                  <Container flexbox justifyContent="flex-end">
                    <Button
                      classNames={['mt-4']}
                      label={string_table.NEXT}
                      variant="primary"
                      onClick={() => nextClicked()}
                      disabled={!phoneFormObject.isValid}
                    />
                  </Container>
                </>
              </Accordion.Content>
            </Accordion.Item>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Accordion.Item value="item-2" classNames={['mt-4']} border={false}>
              <Accordion.Trigger title={`2. ${string_table.MFA_ADD_SMS_VERIFY_STEP}`} />
              <Accordion.Content>
                <>
                  <Container>
                    <Container flexbox flexDirection="row" alignItems="center">
                      <Text
                        text={`${string_table.NAME}: ${factor ? factor.name : ''} `}
                        textSize="large"
                        classNames={['pr-2']}
                      />
                      <Button variant="tertiary" onClick={resendCode} label="Resend code" />
                    </Container>
                    {codeFormObject.renderForm()}
                  </Container>
                  <Container flexbox justifyContent="flex-end">
                    <Button
                      classNames={['mt-4']}
                      label={string_table.VERIFY}
                      variant="primary"
                      onClick={() => nextClicked()}
                      disabled={!codeFormObject.isValid}
                    />
                  </Container>
                </>
              </Accordion.Content>
            </Accordion.Item>
          </Card.Content>
        </Card>
      </Accordion.Root>
      <Container flexbox justifyContent="flex-end" classNames={['my-4', 'mr-8']}>
        <Button
          variant="secondary"
          label={string_table.CANCEL}
          onClick={() => {
            factorAddingCanceled(factor.id);
          }}
        />
      </Container>
    </Container>
  );
};

export default AddSMSFactor;
