import Config from '../config';
import {
  AssociatesCommunicationPreferenceTypeResource,
  CommunicationPreferencesResource,
  CommunicationPreferencesTypeResource,
} from '@legalshield/frontend-commons/dist/sdk/profiles';

type CommunicationPreferencesResourceKey = keyof CommunicationPreferencesResource;
type CommunicationPreferencesTypeResourceKey =
  | keyof CommunicationPreferencesTypeResource
  | keyof AssociatesCommunicationPreferenceTypeResource;

export interface MessageTypeMapping {
  iterableId: number;
  title: string;
  communicationPreference: CommunicationPreferencesResourceKey;
  communicationPreferenceType: CommunicationPreferencesTypeResourceKey;
}

const messageTypes: MessageTypeMapping[] = [
  {
    communicationPreference: 'identity',
    communicationPreferenceType: 'news',
    iterableId: 40963,
    title: 'IDS Member News',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'surveysFeedback',
    iterableId: 51184,
    title: 'LS Survey & Feedback',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'promotions',
    iterableId: 39311,
    title: 'LS Member Promotions',
  },
  {
    communicationPreference: 'identity',
    communicationPreferenceType: 'promotions',
    iterableId: 48467,
    title: 'IDS Member Promotions',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'news',
    iterableId: 49107,
    title: 'LS News',
  },
  {
    communicationPreference: 'associate',
    communicationPreferenceType: 'updatesAndIncentives',
    iterableId: 55827,
    title: 'LS Associate Weekly Update',
  },
  {
    communicationPreference: 'associate',
    communicationPreferenceType: 'updatesAndIncentives',
    iterableId: 49423,
    title: 'LS Associate Incentives',
  },
  {
    communicationPreference: 'identity',
    communicationPreferenceType: 'product',
    iterableId: 98232,
    title: 'IDS Product Updates',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'product',
    iterableId: 98233,
    title: 'LS Product Updates',
  },
];

const messageTypes_test: MessageTypeMapping[] = [
  {
    communicationPreference: 'associate',
    communicationPreferenceType: 'updatesAndIncentives',
    iterableId: 40962,
    title: 'IDShield Marketing Message',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'surveysFeedback',
    iterableId: 71351,
    title: 'Feedback',
  },
  {
    communicationPreference: 'associate',
    communicationPreferenceType: 'all',
    iterableId: 71350,
    title: 'LegalShield Associates',
  },
  {
    communicationPreference: 'legal',
    communicationPreferenceType: 'promotions',
    iterableId: 71349,
    title: 'LegalShield Promotions UAT',
  },
  {
    communicationPreference: 'identity',
    communicationPreferenceType: 'product',
    iterableId: 39314,
    title: 'LegalShield Marketing Message',
  },
];

function getMessageTypes(): MessageTypeMapping[] {
  const lsEnv = Config.getLsEnv();
  switch (lsEnv) {
    case 'dev':
    case 'uat':
      return messageTypes_test;
    case 'prod':
      return messageTypes;
  }
}

export default getMessageTypes;
