import React, { useEffect } from 'react';
import { Container, useForm } from '@legalshield/adonis-ux-framework';

import { useUpdateDob } from '../../../hooks/useProfile';
import { ButtonBar } from '../../Controls/ButtonBar';
import { DateOfBirthPresenter } from '../../NewProfile/Presenters';

import './NewDateOfBirth.scss';

export interface NewDateOfBirthProps {
  data: DateOfBirthPresenter;
  onClose: (failure?: boolean) => void;
  loader: any;
}

export const NewDateOfBirth: React.FC<NewDateOfBirthProps> = ({ data, onClose, loader }: NewDateOfBirthProps) => {
  const newDateOfBirthProp = data?.dateOfBirth || '';
  const splitDob = newDateOfBirthProp.split('-', 3);
  const year = splitDob[0] || '';
  const month = splitDob[1] || '';
  const day = splitDob[2] || '';
  const version = data?.version;
  const [formValues, formObject] = useForm(
    {
      dob: {
        label: string_table.PROFILE_BIRTH_DATE,
        required: true,
        type: 'date',
        value: newDateOfBirthProp,
      },
    },
    null,
  );

  const { dob } = formValues;
  const { mutate, isLoading, isSuccess, isError } = useUpdateDob(version);

  const updateDateOfBirth = async () => {
    mutate({ dateOfBirth: dob.value });
  };

  useEffect(() => {
    if (isSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
      onClose();
    } else if (isError) {
      loader.Error(string_table.ALERT_ERROR);
      onClose(true);
    } else if (isLoading) {
      loader.Loading();
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      <Container flexbox justifyContent={'space-between'} id="dateOfBirthForm" data-testid="dateOfBirthForm">
        {formObject.renderForm()}
      </Container>
      <ButtonBar
        alignLeft={true}
        id="dateOfBirthBtnBar"
        data-testid="dateOfBirthBtnBar"
        disableSave={!formObject.isValid || !formObject.hasChanged}
        onSave={() => updateDateOfBirth()}
      />
    </>
  );
};
