import React from 'react';
import { Button, Container, Heading, Text } from '@legalshield/adonis-ux-framework';

export interface EditContainerProps {
  title: string;
  data: string | string[];
  description?: string;
  onClick?: () => void;
  disabled?: boolean;
  buttonText?: string;
  classNames?: string[];
  id?: string;
}

export const EditContainer: React.FC<EditContainerProps> = ({
  title,
  data,
  description = null,
  onClick = null,
  disabled = false,
  buttonText = null,
  classNames = [],
  id = null,
}: EditContainerProps) => {
  return (
    <Container classNames={['p-5'].concat(classNames)} id={id}>
      <Heading as="T14" text={title} classNames={['mb-3']} />
      {typeof data === 'string' ? (
        <Text as="p" textSize="small" text={data} />
      ) : (
        <>
          {data?.map((item, index) => {
            const label = index === 0 ? `${string_table.PREFERRED}: ${item}` : item;
            return <Text as="p" key={`${index} desc`} textSize="small" text={label} />;
          })}
        </>
      )}
      {onClick && (
        <Container classNames={['mt-2']} flexbox justifyContent="flex-end">
          <Button
            variant="secondary"
            label={buttonText ? buttonText : string_table.EDIT}
            disabled={disabled}
            iconRight="action_edit"
            onClick={onClick}
          />
        </Container>
      )}
      {description && <Text classNames={['mt-5', 'edit-description']} text={description} textSize="small" as="p" />}
    </Container>
  );
};
