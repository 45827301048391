import { AddressResource } from '@legalshield/frontend-commons/dist/sdk/profiles';

export class AddressPresenter {
  address: AddressResource;
  addressString: string;
  version: number;

  constructor(address: AddressResource, addressString: string, version: number) {
    this.address = address;
    this.addressString = addressString || '';
    this.version = version;
  }
}
