import { Api as GroupApi, GroupResource } from '@legalshield/frontend-commons/dist/sdk/groups';
import { useQuery } from '@tanstack/react-query';

const groupApi = new GroupApi();

export const getGroupByGroupId = async (groupId: string): Promise<GroupResource> => {
  if (!groupId) {
    return null;
  }

  const { data: group } = await groupApi.v1.groupsGetGroupById(groupId, {
    withCredentials: true,
  });
  return group;
};

export const useGetGroupByGroupId = (groupId: string) => {
  return useQuery({
    queryFn: () => getGroupByGroupId(groupId),
    queryKey: ['group', groupId],
  });
};
