import { useAddPhone, useRemovePhone, useUpdatePhone } from '../../../hooks/useProfile';
import { Button, Checkbox, Container, FormField, LinkContent, Text, useForm } from '@legalshield/adonis-ux-framework';
import BadgeIconControl from '../../Controls/BadgeIcon/BadgeIconControl';
import React, { useEffect, useState } from 'react';
import { PhoneNumberType, UpdatePhoneNumberRequest } from '@legalshield/frontend-commons/dist/sdk/profiles';
import { formatPhoneNumberWithDash, uppercaseFirstLetter } from '../../../helpers/stringFormatter';
import { waitInSeconds } from '../../../utils/utils';

export interface NewPhoneNumberProps {
  index: number;
  indexBeingEdited?: number | null;
  number: string;
  onEditPhoneNumber(index?: number): void;
  preferred: boolean;
  type: string;
  version?: number;
  loader: any;
}

const typeOptions = [
  { label: string_table.PROFILE_PHONE_NUMBER_TYPE_CELL, value: PhoneNumberType.Cell },
  { label: string_table.PROFILE_PHONE_NUMBER_TYPE_HOME, value: PhoneNumberType.Home },
  { label: string_table.PROFILE_PHONE_NUMBER_TYPE_WORK, value: PhoneNumberType.Work },
];

export const NewPhoneNumber: React.FC<NewPhoneNumberProps> = ({
  index,
  indexBeingEdited,
  number,
  onEditPhoneNumber,
  preferred,
  type,
  version,
  loader,
}: NewPhoneNumberProps) => {
  const isReadOnly = index !== indexBeingEdited;
  const [isNewRow] = useState(number === '');
  const [isEditing, setIsEditing] = useState(false);
  const [isPhonePreferred, setIsPhonePreferred] = useState(preferred);

  const {
    mutate: updateMutate,
    isSuccess: updateIsSuccess,
    isError: updateIsError,
    isLoading: updateIsLoading,
  } = useUpdatePhone(version);
  const {
    mutate: addMutate,
    isSuccess: addIsSuccess,
    isError: addIsError,
    isLoading: addIsLoading,
  } = useAddPhone(version);
  const {
    mutate: removeMutate,
    isSuccess: removeIsSuccess,
    isError: removeIsError,
    isLoading: removeIsLoading,
  } = useRemovePhone(version);

  const [formValues, formObject] = useForm({}, null);

  useEffect(() => {
    !isReadOnly ? setIsEditing(true) : setIsEditing(false);
  }, [isReadOnly]);

  useEffect(() => {
    formObject.setValues({
      phoneNumber: {
        errorMessage: string_table.PROFILE_PHONE_NUMBER_ERROR,
        format: '###-###-####',
        isValid: number != '',
        label: string_table.PROFILE_PHONE_NUMBER,
        readOnly: isReadOnly,
        regEx: /^\d{3}-\d{3}-\d{4}$/,
        required: true,
        type: 'tel',
        value: number,
      },
      phoneType: {
        isValid: true,
        label: string_table.PROFILE_PHONE_TYPE,
        options: typeOptions,
        readOnly: isReadOnly,
        required: true,
        type: isReadOnly ? 'text' : 'select',
        value: type,
      },
    });
  }, [isReadOnly, number, type, preferred]);

  useEffect(() => {
    if (addIsError || removeIsError || updateIsError) {
      loader.Error(string_table.ALERT_ERROR);
    } else if (addIsSuccess || removeIsSuccess || updateIsSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
    }
    if (addIsLoading || removeIsLoading || updateIsLoading) {
      loader.Loading();
    }
  }, [
    addIsError,
    removeIsError,
    updateIsError,
    addIsLoading,
    removeIsLoading,
    updateIsLoading,
    addIsSuccess,
    removeIsSuccess,
    updateIsSuccess,
  ]);

  const addPhoneNumber = async () => {
    addMutate({
      number: formValues['phoneNumber'].value,
      preferred: isPhonePreferred,
      type: formValues['phoneType'].value as PhoneNumberType,
      verified: false,
    });

    onEditPhoneNumber(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  const updatePhoneNumber = async () => {
    const updatePhoneReq: UpdatePhoneNumberRequest = {
      number: formValues['phoneNumber'].value,
      preferred: isPhonePreferred,
      type: formValues['phoneType'].value as PhoneNumberType,
    };

    updateMutate({
      oldNumber: number,
      oldType: type,
      req: updatePhoneReq,
    });

    onEditPhoneNumber(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  const deletePhoneNumber = async () => {
    removeMutate({
      number: formValues['phoneNumber'].value,
      type: formValues['phoneType'].value as PhoneNumberType,
    });

    onEditPhoneNumber(null);
    setIsEditing(false);
    await waitInSeconds(5);
    loader.Blank();
  };

  const editPhoneNumber = () => {
    loader.Blank();
    onEditPhoneNumber(index);
    setIsEditing(true);
  };

  return (
    <>
      {!isEditing && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Text
                textSize={'large'}
                text={`${formatPhoneNumberWithDash(number)} - ${uppercaseFirstLetter(type)}`}
                classNames={['pt-2 pb-2 lsux-list-item__text-secondary']}
              />
              {preferred ? (
                <BadgeIconControl
                  style={{ borderRadius: '5px', maxHeight: '24px', padding: '4px 10px 4px 6px' }}
                  classNames={['ml-4']}
                  icon={'interface_check'}
                  text={string_table.PREFERRED}
                  variant="primary"
                  small={true}
                  circle={false}
                  textWeight={'semibold'}
                />
              ) : null}
            </div>
            <LinkContent
              key={`phoneNumberEditOption`}
              classNames={['pr-4']}
              linkContentStyle="link"
              text={string_table.EDIT}
              disabled={indexBeingEdited != null}
              onClick={(_) => {
                editPhoneNumber();
              }}
            />
          </div>
        </>
      )}
      {isEditing && (
        <>
          <Container id="phoneNumberForm" data-testid="phoneNumberForm">
            {formObject.renderForm()}
            <FormField id="phoneIsPreferred" required={true}>
              <Checkbox
                id="phoneIsPreferred"
                name="phoneIsPreferred"
                checked={isPhonePreferred}
                rightLabel={string_table.PROFILE_SET_AS_PREFERRED}
                onCheckedChange={setIsPhonePreferred}
                disabled={preferred}
                classNames={['mt-4']}
              />
            </FormField>
            <Container id={'phoneNumberBtnBar'} data-testid={'phoneNumberBtnBar'} classNames={['pt-6']}>
              <Container flexbox>
                <Button
                  classNames={['mr-2']}
                  style={{ boxShadow: '0 0 0 1.5px red', color: 'red' }}
                  textSize="small"
                  onClick={() => deletePhoneNumber()}
                  disabled={preferred || isNewRow}
                  variant="secondary"
                  label={string_table.REMOVE}
                />
                <Button
                  classNames={['ml-2']}
                  onClick={() => (isNewRow ? addPhoneNumber() : updatePhoneNumber())}
                  label={string_table.SAVE}
                  disabled={!formObject.isValid}
                  variant="primary"
                  textSize="small"
                />
              </Container>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};
