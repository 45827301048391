// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tel_text-color {
  color: var(--n700);
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/Subscriptions/CallForQuestions/CallForQuestions.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".tel_text-color {\n  color: var(--n700);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
