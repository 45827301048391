import React from 'react';
import { Breadcrumbs, Container, Icon } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

const BreadcrumbBar = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      {
        <Container background='light-gray' alignItems="center" flexbox classNames={['pb-4']}>
          <Icon name="nav_overview" />
          <Breadcrumbs crumbs={props.crumbs} maxTail={3} onClick={(link) => navigate(link)} />
        </Container>
      }
    </>
  );
};

export default BreadcrumbBar;
