import React, { useEffect, useState } from 'react';
import AddAuthenticatorFactor from './AddAuthenticatorFactor';
import AddSMSFactor from './AddSMSFactor';
import { FactorDisplay } from './FactorDisplay';
import { FactorResponse } from '@legalshield/frontend-commons/dist/sdk/factors';
import { useDeleteFactor, usePromoteFactor } from '../../../hooks/useIdentity';
import {
  Button,
  Card,
  Grid,
  GridCol,
  GridRow,
  Heading,
  Loader,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';

export interface FactorsProps {
  factors: FactorResponse[] | null;
  setAddingFactor: (value: boolean) => void;
}

const Factors: React.FC<FactorsProps> = ({ factors, setAddingFactor }: FactorsProps) => {
  const loader = useLoader();
  const [addingGA, setAddingGA] = useState(false);
  const [addingPhone, setAddingPhone] = useState(false);
  const {
    mutate: deleteFactor,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useDeleteFactor();
  const {
    mutate: promoteFactor,
    isError: isPromoteError,
    isSuccess: isPromoteSuccess,
    isLoading: isPromoteLoading,
  } = usePromoteFactor();

  useEffect(() => {
    if (isDeleteLoading) {
      loader.Loading();
    }
    if (isDeleteError) {
      loader.Error(string_table.MFA_FACTORS_ERRORDELETE);
    }
    if (isDeleteSuccess) {
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteError, isDeleteSuccess, isDeleteLoading]);

  useEffect(() => {
    if (isPromoteLoading) {
      loader.Loading();
    }
    if (isPromoteError) {
      loader.Error(string_table.MFA_FACTORS_ERRORPROMOTE);
    }
    if (isPromoteSuccess) {
      loader.Success(string_table.MFA_FACTORS_PROMOTESUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPromoteLoading, isPromoteError, isPromoteSuccess]);

  function factorAddedSuccessfully() {
    setAddingFactor(false);
    setAddingGA(false);
    setAddingPhone(false);
  }

  async function factorAddingCanceled(id: string) {
    setAddingFactor(false);
    setAddingGA(false);
    setAddingPhone(false);
    if (id) {
      await deleteFactor(id);
    }
  }

  return (
    <>
      <Loader loaderState={loader.loaderState} />
      <div className={'mt-3'}>
        {addingGA && (
          <AddAuthenticatorFactor
            loader={loader}
            factorAddingCanceled={factorAddingCanceled}
            factorAddedSuccessfully={factorAddedSuccessfully}
            factorsLength={factors?.length}
          />
        )}
        {addingPhone && (
          <AddSMSFactor
            loader={loader}
            factorAddingCanceled={factorAddingCanceled}
            factorAddedSuccessfully={factorAddedSuccessfully}
            factorsLength={factors?.length}
          />
        )}
        {!addingGA && !addingPhone && (
          <>
            {factors && factors.length > 0 && (
              <Card>
                <Card.Content>
                  <Card.Title>{string_table.MFA_FACTORS_TITLE}</Card.Title>
                  <Grid>
                    <GridRow variant="halves">
                      {factors.map((item) => {
                        return (
                          <GridCol key={item.id}>
                            <FactorDisplay
                              item={item}
                              onPromote={() => promoteFactor(item.id)}
                              onDelete={() => deleteFactor(item.id)}
                            />
                          </GridCol>
                        );
                      })}
                    </GridRow>
                  </Grid>
                </Card.Content>
              </Card>
            )}
            <Card classNames={['mt-4']}>
              <Card.Content>
                <Card.Title>{string_table.MFA_FACTORS_ADD}</Card.Title>
                <div>
                  <Heading as="T20" className="mb-4" text="SMS" />
                  <Button
                    variant="secondary"
                    label={string_table.MFA_FACTORS_ADDSMS}
                    iconRight="action_add"
                    onClick={() => {
                      setAddingPhone(true);
                      setAddingFactor(true);
                    }}
                  />
                </div>
                <Text className="mt-2" textSize="small" text={string_table.MFA_FACTORS_SMS_DESC} />
                <div className="mt-6">
                  <Heading as="T20" className="mb-4" text="Authenticator" />
                  <Button
                    variant="secondary"
                    label={string_table.MFA_FACTORS_ADDAUTHENTICATOR}
                    iconRight="action_add"
                    onClick={() => {
                      setAddingGA(true);
                      setAddingFactor(true);
                    }}
                  />
                </div>
                <Text className="mt-2" textSize="small" text={string_table.MFA_FACTORS_AUTHENTICATOR_DESC} />
              </Card.Content>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default Factors;
