import { IInvitation, InvitationResponseSecurityQuestion } from '../interfaces/invitation.interface';

export class InvitationPresenter {
  invitation: IInvitation | null;

  constructor(invitation: IInvitation) {
    this.invitation = invitation;
  }

  hasSecurityQuestions(): boolean {
    return this.securityQuestions().length > 0;
  }

  securityQuestions(): InvitationResponseSecurityQuestion[] {
    // TODO: use a webpack setup that allows typescript's null-safe operator (?)
    return ((this.invitation || {}).verification || {}).securityQuestions || [];
  }
}
