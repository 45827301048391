import { AnalyticServiceVendor, AnalyticsOptions, ThemisAnalytics } from '@legalshield/frontend-analytics';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './components/App/App';
import Config from './config';
import { AlertsProvider } from './components/Alerts/Banner/context/BannerAlertContext';
import { ModalAlertProvider } from './components/Alerts/Modal/context/ModalAlertContext';

const root = createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

const analyticsConfig: AnalyticsOptions = {
  client: AnalyticServiceVendor.GoogleTagManager,
  config: {
    gtmService: Config.currentEnv.gtm,
  },
};
const themisAnalytics = new ThemisAnalytics(analyticsConfig);
themisAnalytics.initialize();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AlertsProvider>
          <ModalAlertProvider>
            <App />
          </ModalAlertProvider>
        </AlertsProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

export { themisAnalytics };
