import { PhoneNumberResource } from '@legalshield/frontend-commons/dist/sdk/profiles';

export class PhoneNumberPresenter {
  phoneNumbers: PhoneNumberResource[];
  version: number;

  constructor(phoneNumbers: PhoneNumberResource[], version: number) {
    this.phoneNumbers = phoneNumbers || [];
    this.version = version;
  }
}
