// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accounts-card {
  background-color: var(--n00);
  border: 1px solid var(--n350);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: inherit;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
.accounts-card--clickable {
  cursor: pointer;
}
.accounts-card--clickable:hover, .accounts-card--clickable:focus {
  border: 1px solid var(--n450);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.accounts-card--clickable:active {
  border: 1px solid var(--n500);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.accounts-card--clickable:focus {
  outline: 2px solid var(--brand-color-prim200);
}
.accounts-card__text {
  color: #737373;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/CardButton.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,6BAAA;EACA,wCAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,qBAAA;EACA,2BAAA;AACF;AACE;EACE,eAAA;AACJ;AACI;EAEE,6BAAA;EACA,0CAAA;AAAN;AAGI;EACE,6BAAA;EACA,wCAAA;AADN;AAII;EACE,6CAAA;AAFN;AAME;EACE,cAAA;AAJJ","sourcesContent":[".accounts-card {\n  background-color: var(--n00);\n  border: 1px solid var(--n350);\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  color: inherit;\n  height: 100%;\n  overflow: hidden;\n  text-decoration: none;\n  transition: box-shadow 0.2s;\n\n  &--clickable {\n    cursor: pointer;\n\n    &:hover,\n    &:focus {\n      border: 1px solid var(--n450);\n      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);\n    }\n\n    &:active {\n      border: 1px solid var(--n500);\n      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    }\n\n    &:focus {\n      outline: 2px solid var(--brand-color-prim200);\n    }\n  }\n\n  &__text {\n    color: #737373;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
