import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import {
  Container,
  Heading,
  Grid,
  GridRow,
  GridCol,
  Text,
  Loader,
  useLoader,
  Crumb,
} from '@legalshield/adonis-ux-framework';
import BreadcrumbBar from '../Controls/BreadcrumbBar';

export interface LayoutProps {
  /**
   * ID of the layout
   * @default undefined
   */
  id?: string;
  /**
   * The breadcrumbs of the layout
   * @default undefined
   */
  crumbs?: Crumb[];
  /**
   * Defines if the breadcrumbs will be shown. If true, the breadcrumbs will be displayed
   * @default undefined
   */
  showBreadcrumbs?: boolean;
  /**
   * The title of the layout
   * @default undefined
   */
  title?: string;
  /**
   * The subheading of the layout
   * @default undefined
   */
  subtitle?: string | ReactNode;
  /**
   * The children of the layout
   * @default undefined
   */
  children?: ReactNode | ReactNode[];
  /**
   * JSX for elements above the breadcrumbs
   */
  bannerElement?: ReactElement;
}

export const Layout: FC<LayoutProps> = ({
  children,
  crumbs,
  id,
  showBreadcrumbs = true,
  subtitle = null,
  title,
  bannerElement,
}) => {
  const loader = useLoader();

  return (
    <>
      <Loader loaderState={loader.loaderState} />
      <Container flexbox flexDirection="column" background="light-gray" id={id}>
        <Grid>
          <GridRow variant="center">
            <GridCol>
              {showBreadcrumbs && crumbs ? <BreadcrumbBar crumbs={crumbs || ''} /> : <Fragment />}
              {title ? <Heading text={title} as="T31" /> : <Fragment />}
              {subtitle && typeof subtitle == 'string' ? (
                <Text text={subtitle} textSize="large" classNames={'pt-3'} />
              ) : (
                (subtitle as ReactElement)
              )}
              <div className={`mt-6 mb-6`}>{bannerElement}</div>
              {children as ReactElement}
            </GridCol>
          </GridRow>
        </Grid>
      </Container>
    </>
  );
};
