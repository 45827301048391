import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useUrlParamManager = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setParameter = useCallback(
    (param: string, value: string | number | boolean | undefined) => {
      try {
        const searchParams = new URLSearchParams(location.search);

        if (value === undefined) {
          searchParams.delete(param);
        } else {
          searchParams.set(param, String(value));
        }

        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      } catch (error) {
        console.error('Error setting URL paramter:', error);
      }
    },
    [navigate, location],
  );

  const clearParameter = useCallback(
    (param: string) => {
      try {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      } catch (error) {
        console.error('Error clearing URL paramter:', error);
      }
    },
    [navigate, location],
  );

  const clearParameters = useCallback(() => {
    try {
      navigate(location.pathname, { replace: true });
    } catch (error) {
      console.error('Error clearing all URL paramaters', error);
    }
  }, [navigate, location]);

  const getParameter = useCallback(
    (param: string) => {
      try {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
      } catch (error) {
        console.error('Error retrieving URL paramter:', error);
      }
    },
    [location],
  );

  return { clearParameter, clearParameters, getParameter, setParameter };
};
