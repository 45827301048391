import React, { useEffect, useState } from 'react';
import { Container, Text, useForm } from '@legalshield/adonis-ux-framework';
import { ButtonBar } from '../../Controls/ButtonBar';
import { useEditAddress } from '../../../hooks/useProfile';
import { allStatesVals } from '../../../utils/states';
import RealmUtility from '../../../utils/realm';
import { AddressPresenter } from '../../NewProfile/Presenters';

export interface NewAddressProps {
  data: AddressPresenter;
  onClose: (failure?: boolean) => void;
  loader: any;
}

const countryOptions = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
];

export const NewAddress: React.FC<NewAddressProps> = ({ data, onClose, loader }: NewAddressProps) => {
  const newAddressProp = data?.address;
  const version = data?.version;
  const { mutate, isSuccess, isError, isLoading } = useEditAddress(version, newAddressProp === null);
  const [isUser] = useState(RealmUtility.isUser());
  /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
  const [formValues, formObject] = useForm(
    {
      address1: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: string_table.PROFILE_ADDRESS_1,
        required: true,
        type: 'text',
        value: newAddressProp?.address1 ?? '',
      },
      address2: {
        label: string_table.PROFILE_ADDRESS_2,
        type: 'text',
        value: newAddressProp?.address2 ?? '',
      },
      locality: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: string_table.PROFILE_ADDRESS_CITY,
        required: true,
        type: 'text',
        value: newAddressProp?.locality ?? '',
      },
      country: {
        disabled: isUser,
        label: string_table.PROFILE_ADDRESS_COUNTRY,
        options: countryOptions,
        type: 'select',
        value: newAddressProp?.country ?? countryOptions[0].value,
      },
      administrativeArea: {
        disabled: isUser,
        label: string_table.PROFILE_ADDRESS_STATE,
        options: allStatesVals,
        type: 'select',
        value: newAddressProp?.administrativeArea ?? allStatesVals[0].value,
      },
      postalCode: {
        errorMessage: string_table.PROFILE_FIELD_REQUIRED,
        label: string_table.PROFILE_ADDRESS_ZIP,
        required: true,
        type: 'text',
        value: newAddressProp?.postalCode ?? '',
      },
    },
    null,
  );
  /* eslint-enable sort-keys, sort-keys-fix/sort-keys-fix */

  const { address1, address2, administrativeArea, locality, postalCode, country } = formValues;

  const editAddress = async () => {
    mutate({
      address1: address1.value,
      address2: address2.value,
      administrativeArea: administrativeArea.value,
      country: country.value,
      locality: locality.value,
      postalCode: postalCode.value,
      preferred: true,
      type: newAddressProp?.type ?? 'home',
    });
  };

  useEffect(() => {
    if (isSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
      onClose();
    } else if (isError) {
      loader.Error(string_table.ALERT_ERROR);
      onClose();
    } else if (isLoading) {
      loader.Loading();
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <>
      {isUser && newAddressProp === null ? (
        <Text text={string_table.PLEASE_PURCHASE_OR_ACTIVATE_PRODUCTS} />
      ) : (
        <div id="addressForm" data-testid="addressForm">
          {formObject.renderForm()}
        </div>
      )}
      <ButtonBar
        alignLeft={true}
        id={'addressBtnBar'}
        data-testid="addressBtnBar"
        disableSave={!formObject.isValid || !formObject.hasChanged || isLoading}
        onSave={() => editAddress()}
      />
    </>
  );
};
