import React from 'react';
import { Container, Modal, Text } from '@legalshield/adonis-ux-framework';
import { useModalAlert } from './context/ModalAlertContext';

type ModalAlertProps = {
  message: string;
  title: string;
};

const ModalAlert = ({ message, title }: ModalAlertProps) => {
  const { modalAlertDispatch, modalAlertState } = useModalAlert();

  const handleDismiss = () => {
    modalAlertDispatch({ type: 'DISMISS' });
  };

  return (
    <Modal open={modalAlertState?.displayed} closeButton={true} closeFunction={handleDismiss} position="center">
      <Modal.Title id="modal-alert-title">{title}</Modal.Title>
      <Container id="modal-alert-container" classNames={['mt-4']}>
        <Text id="modal-alert-message" text={message} />
      </Container>
    </Modal>
  );
};

export default ModalAlert;
