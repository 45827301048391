import React, { createContext, useReducer, Dispatch, useContext, FC } from 'react';
import { Severity } from '@legalshield/adonis-ux-framework';
import BannerAlert from '../BannerAlert';

type BannerAlertState = {
  alertType?: 'alert' | 'snackbar';
  displayed: boolean;
  message: string;
  severity: Severity | undefined;
  title?: string;
};

type BannerAlertAction = DisplayBannerAlertAction | DismissAlertAction;

type BannerAlertContextType = {
  bannerAlertState: BannerAlertState;
  bannerAlertDispatch: Dispatch<BannerAlertAction>;
};

type DisplayBannerAlertAction = {
  type: 'DISPLAY';
  alertType?: 'alert' | 'snackbar';
  message: string;
  severity: Severity;
  title?: string;
};

type DismissAlertAction = {
  type: 'DISMISS';
};

type BannerAlertProviderProps = {
  children: React.ReactNode;
};

export const bannerAlertReducer = (
  bannerAlertState: BannerAlertState,
  bannerAlertAction: BannerAlertAction,
): BannerAlertState => {
  switch (bannerAlertAction.type) {
    case 'DISPLAY':
      return {
        displayed: true,
        message: bannerAlertAction.message,
        severity: bannerAlertAction.severity,
        title: bannerAlertAction.title,
      };
    case 'DISMISS':
      return {
        displayed: false,
        message: '',
        severity: undefined,
        title: '',
      };

    default:
      return bannerAlertState;
  }
};

export const AlertsProvider: FC<BannerAlertProviderProps> = ({ children }) => {
  const initialState: BannerAlertState = {
    displayed: false,
    message: '',
    severity: undefined,
    title: '',
  };

  const [bannerAlertState, bannerAlertDispatch] = useReducer(bannerAlertReducer, initialState);

  return (
    <BannerAlertContext.Provider value={{ bannerAlertDispatch, bannerAlertState }}>
      {bannerAlertState?.displayed ? (
        <BannerAlert
          alertType={bannerAlertState?.alertType}
          title={bannerAlertState?.title}
          message={bannerAlertState.message}
          severity={bannerAlertState.severity}
        />
      ) : null}
      {children}
    </BannerAlertContext.Provider>
  );
};

export const BannerAlertContext = createContext<BannerAlertContextType>({
  bannerAlertDispatch: () => undefined,
  bannerAlertState: {
    displayed: false,
    message: '',
    severity: undefined,
    title: '',
  },
});

export const useBannerAlert = () => {
  const context = useContext(BannerAlertContext);
  if (!context) {
    throw new Error('useBannerAlert must be used within AlertsProvider');
  }

  return context;
};
