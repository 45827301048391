import { Api as SubscriptionsApi } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { useQuery } from '@tanstack/react-query';

const subscriptionsApi = new SubscriptionsApi();

const identityId = pplsi?.authNPayload?.sub;

const getOutstandingInvoices = async (subscriptionId: string) => {
  const { data: outstandingInvoices } = await subscriptionsApi.v1.subscriptionsGetOutstandingInvoices(
    identityId,
    subscriptionId,
    {
      withCredentials: true,
    },
  );

  return outstandingInvoices;
};

export const useGetOutstandingInvoices = (subscriptionId: string) => {
  return useQuery({
    queryFn: () => getOutstandingInvoices(subscriptionId),
    queryKey: ['outstanding-invoices', subscriptionId],
  });
};
