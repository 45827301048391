import {
  Button,
  Card,
  Grid,
  GridCol,
  GridRow,
  Heading,
  Loader,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';
import React, { useEffect } from 'react';
import {
  AssociatesCommunicationPreferenceTypeResource,
  CommunicationPreferencesTypeResource,
} from '@legalshield/frontend-commons/dist/sdk/profiles';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUpdateSpecificCommunication } from '../../hooks/useProfile';
import getMessageTypes, { MessageTypeMapping } from '../../utils/messageTypes';
import { stringFormat } from '../../utils/stringFormat';

type CommunicationPreferencesTypeResourceKey =
  | keyof CommunicationPreferencesTypeResource
  | keyof AssociatesCommunicationPreferenceTypeResource;

const Unsubscribe: React.FC = () => {
  const { identityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const messageId = searchParams.get('id');
  const message = getMessageTypes().find((ele: MessageTypeMapping) => {
    return ele.iterableId.toString() == messageId;
  });

  const loader = useLoader();
  const {
    mutateAsync,
    isSuccess: isUpdateCommSuccess,
    isLoading: isUpdateCommLoading,
    isError: isUpdateCommError,
  } = useUpdateSpecificCommunication(message.communicationPreference, identityId);

  const updateSpecificCommunicationPreferences = async (
    communicationPreferenceType: CommunicationPreferencesTypeResourceKey,
  ) => {
    const newVal: Record<string, boolean> = JSON.parse(`{ "${communicationPreferenceType}": false }`);
    try {
      await mutateAsync(newVal as Record<string, boolean>);
    } catch (error) {}
  };

  useEffect(() => {
    if (isUpdateCommError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isUpdateCommLoading) {
      loader.Loading();
    }
    if (isUpdateCommSuccess) {
      loader.Success(string_table.ALERT_SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <>
      <Loader loaderState={loader.loaderState} />
      <Grid>
        <GridRow>
          <GridCol>
            <div style={{ textAlign: 'center' }}>
              <Heading text={string_table.PREFERENCES_TITLE} as="T26" />
            </div>
          </GridCol>
        </GridRow>
        <GridRow variant="plain">
          <GridCol>
            <Card cardHeight="100%">
              <Card.Content>
                <Text as="p">
                  {stringFormat(string_table.PREFERENCES_UNSUBSCRIBE_BY_IDENTITY_TITLE, [message.title])}
                </Text>
                <Card.Actions>
                  <Button
                    label={string_table.PREFERENCES_UNSUBSCRIBE_BY_IDENTITY_BUTTON}
                    onClick={() => updateSpecificCommunicationPreferences(message.communicationPreferenceType)}
                  />
                </Card.Actions>
              </Card.Content>
            </Card>
          </GridCol>
        </GridRow>
      </Grid>
    </>
  );
};

export default Unsubscribe;
