// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accounts-subscription-preview {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .accounts-subscription-preview:nth-child(1) {
    flex-direction: column;
  }
  .accounts-subscription-preview:nth-child(1) > div {
    margin-top: 1rem;
  }
}
.accounts-subscription-item__header-price {
  color: var(--n800);
}
.accounts-subscription-item__header-bill-date {
  color: var(--n700);
}
.accounts-subscription-item__content {
  background-color: var(--n50);
  display: flex;
  flex-direction: row;
}
.accounts-subscription-item__content > div {
  flex: 50%;
}
.accounts-subscription-item__content__section__label {
  margin-bottom: 0.75rem;
}
.accounts-subscription-item__content__section .lsux-text:not(:first-child) {
  color: var(--n700);
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/Subscriptions/Subscription/Subscription.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,OAAA;EACA,8BAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;EAAE;IACE,gBAAA;EAEJ;AACF;AAII;EACE,kBAAA;AAFN;AAII;EACE,kBAAA;AAFN;AAME;EACE,4BAAA;EACA,aAAA;EACA,mBAAA;AAJJ;AAMI;EACE,SAAA;AAJN;AAQM;EACE,sBAAA;AANR;AAQM;EACE,kBAAA;AANR","sourcesContent":[".accounts-subscription-preview {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  justify-content: space-between;\n}\n\n@media screen and (max-width: 768px) {\n  .accounts-subscription-preview:nth-child(1) {\n    flex-direction: column;\n    > div {\n      margin-top: 1rem;\n    }\n  }\n}\n\n.accounts-subscription-item {\n  &__header {\n    &-price {\n      color: var(--n800);\n    }\n    &-bill-date {\n      color: var(--n700);\n    }\n  }\n\n  &__content {\n    background-color: var(--n50);\n    display: flex;\n    flex-direction: row;\n\n    > div {\n      flex: 50%;\n    }\n\n    &__section {\n      &__label {\n        margin-bottom: 0.75rem;\n      }\n      .lsux-text:not(:first-child) {\n        color: var(--n700);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
