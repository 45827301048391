import {
  Container,
  LinkContent,
  ListItem,
  ListItemText,
  Loader,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';
import React, { useState } from 'react';
import { NewName } from '../NewName/NewName';
import { NewPreferredName } from '../NewPreferredName/NewPreferredName';
import { NewDateOfBirth } from '../NewDateOfBirth/NewDateOfBirth';
import { NewAddress } from '../NewAddress/NewAddress';
import { NewEmailContainer } from '../NewEmailContainer/NewEmailContainer';
import { NewPhoneNumberContainer } from '../NewPhoneNumberContainer/NewPhoneNumberContainer';
import { formatPhoneNumberWithDash, uppercaseFirstLetter } from '../../../helpers/stringFormatter';
import RealmUtility from '../../../utils/realm';
import './NewEditContainer.scss';
import BadgeIconControl from '../../Controls/BadgeIcon/BadgeIconControl';

export interface NewEditContainerProps {
  data: any;
  fieldName: string;
}

export const NewEditContainer: React.FC<NewEditContainerProps> = ({ data, fieldName }: NewEditContainerProps) => {
  const loader = useLoader();
  const [isEditing, setIsEditing] = useState(false);
  const [isUser] = useState(RealmUtility.isUser());
  const MyComponents = {
    address: NewAddress,
    dateOfBirth: NewDateOfBirth,
    email: NewEmailContainer,
    name: NewName,
    phoneNumber: NewPhoneNumberContainer,
    preferredName: NewPreferredName,
  };

  const fullName = `${data.firstName} ${data.middleName} ${data.lastName}`;
  const emailAddresses =
    data.emails?.map((email) => {
      return { preferred: email.preferred, value: email.address };
    }) || [];
  const formattedPhoneNumbers =
    data.phoneNumbers?.map((phoneNumber) => {
      return {
        preferred: phoneNumber.preferred,
        value: `${formatPhoneNumberWithDash(phoneNumber.number)} - ${uppercaseFirstLetter(phoneNumber.type)}`,
      };
    }) || [];
  const nameDesc = string_table.PROFILE_NAME_CHANGE_DISABLE.replace('%phone%', pplsi.phoneNumber);
  const addressDesc = string_table.PROFILE_ADDRESS_CHANGE_DISABLE.replace('%phone%', pplsi.phoneNumber);

  const displayData = {
    address: { desc: isUser ? addressDesc : null, title: string_table.PROFILE_ADDRESS, value: data.addressString },
    dateOfBirth: { title: string_table.PROFILE_BIRTH_DATE, value: data.formattedDateOfBirth },
    email: { title: string_table.PROFILE_EMAIL, value: emailAddresses },
    name: { desc: isUser ? nameDesc : null, title: string_table.PROFILE_LEGAL_NAME, value: fullName },
    phoneNumber: { title: string_table.PROFILE_PHONE_NUMBER, value: formattedPhoneNumbers },
    preferredName: { title: string_table.PROFILE_PREFERRED_NAME, value: data.preferredName },
  };

  const dataFieldDescription = displayData[fieldName].desc;
  const dataFieldTitle = displayData[fieldName].title;
  const dataFieldValue = displayData[fieldName].value;

  const Render = MyComponents[fieldName];

  function onClose(failure: boolean) {
    if (!failure) {
      loader.Blank();
      setIsEditing(false);
    }
  }

  return (
    <>
      {!isEditing && (
        <>
          <ListItem
            key={`${fieldName}DisplayFieldContainer`}
            data-testid={`${fieldName}DisplayFieldContainer`}
            classNames={['pb-4', 'pt-4']}
          >
            {typeof dataFieldValue === 'string' ? (
              <>
                <Container flexbox style={{ flexDirection: 'column' }}>
                  <div className={fieldName == 'address' ? 'addressListItem' : null} style={{ minWidth: '70%' }}>
                    <ListItemText
                      primary={dataFieldTitle}
                      primaryProps={{ textWeight: 'semibold' }}
                      secondary={dataFieldValue ?? ''}
                      secondaryProps={{ classNames: ['w-50'], textSize: 'large' }}
                    />
                  </div>
                  {dataFieldDescription && (
                    <Container classNames={['mt-2']}>
                      <Text classNames={['edit-description']} text={dataFieldDescription} textSize="small" as="p" />
                    </Container>
                  )}
                </Container>
              </>
            ) : (
              <>
                <div
                  data-testid={`${fieldName}DisplayFieldContainer`}
                  className={'lsux-list-item__text lsux-list-item__text--left'}
                >
                  <div>
                    <div>
                      <Text textSize={'large'} text={dataFieldTitle} classNames={['lsux-list-item__text-primary']} />
                    </div>
                    {dataFieldValue?.map((item, index: number) => {
                      return (
                        <Container key={`${index} desc`}>
                          <Container flexbox style={{ alignItems: 'center' }}>
                            <Text
                              textSize={'large'}
                              text={item.value}
                              classNames={['pt-2 pb-2 lsux-list-item__text-secondary ow-a']}
                            />
                            {item.preferred ? (
                              <BadgeIconControl
                                style={{ borderRadius: '5px', maxHeight: '24px', padding: '4px 10px 4px 6px' }}
                                classNames={['ml-4']}
                                icon={'interface_check'}
                                text={string_table.PREFERRED}
                                variant="primary"
                                small={true}
                                circle={false}
                                textWeight={'semibold'}
                              />
                            ) : null}
                          </Container>
                          {dataFieldDescription && (
                            <Container classNames={['mt-2']}>
                              <Text
                                classNames={['mt-2', 'edit-description']}
                                text={dataFieldDescription}
                                textSize="small"
                                as="p"
                              />
                            </Container>
                          )}
                        </Container>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <LinkContent
              data-testid={`${fieldName}EditButton`}
              classNames={['py-3', 'px-4']}
              linkContentStyle="link"
              disabled={isEditing || dataFieldDescription}
              text={string_table.EDIT}
              onClick={(_) => {
                setIsEditing(true);
              }}
            />
          </ListItem>
        </>
      )}
      {isEditing && (
        <>
          <div id={`${fieldName}EditFieldContainer`} data-testid={`${fieldName}EditFieldContainer`} className={'bb-1'}>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
              <h1>{dataFieldTitle}</h1>
              <LinkContent
                data-testid={`${fieldName}CancelButton`}
                classNames={['py-3', 'px-4']}
                linkContentStyle="link"
                text={string_table.CANCEL}
                onClick={(_) => {
                  onClose(false);
                }}
              />
            </div>
            <Loader loaderState={loader.loaderState} />
            <Render loader={loader} onClose={onClose} data={data} />
          </div>
        </>
      )}
    </>
  );
};
