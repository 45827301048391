import React, { CSSProperties, ForwardRefRenderFunction, IframeHTMLAttributes } from 'react';

interface IframeContainerProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  src: string;
  title: string;
  name: string;
  contentHeight: number;
  className?: string;
}

const IframeContainer: ForwardRefRenderFunction<HTMLIFrameElement, IframeContainerProps> = (
  { src, title, name, className, contentHeight, ...iframeProps },
  ref,
) => {
  const containerStyles: CSSProperties = {
    position: 'relative',
    width: '100%',
    height: contentHeight,
    overflow: 'hidden',
    boxSizing: 'border-box',
  };

  const iframeStyles: CSSProperties = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: 'none',
    transition: '0.5',
  };

  return (
    <div className={className} style={containerStyles}>
      <iframe
        ref={ref}
        src={src}
        title={title}
        name={name}
        frameBorder="0"
        scrolling="no"
        style={iframeStyles}
        {...iframeProps}
      />
    </div>
  );
};

export default React.forwardRef(IframeContainer);
