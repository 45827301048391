import React, { Fragment } from 'react';
import { Card, Container, Heading, Text } from '@legalshield/adonis-ux-framework';

import { Benefits } from './Benefits/Benefits';
import { ISubscriptionResource } from '../hooks/utils/subscription-utils';
import { useGetEligibilityBenefits } from '../hooks/useEligibiltyBenefits';

type SupplementInfoProps = {
  subscription: ISubscriptionResource;
};

export const SupplementInfo = ({ subscription }: SupplementInfoProps) => {
  const { data: eligibilityBenefits } = useGetEligibilityBenefits({ subscription });

  const supplementBenefits = eligibilityBenefits?.flatMap((benefits) => benefits.supplementBenefits);

  if (!supplementBenefits || supplementBenefits?.length <= 0) return <Fragment />;

  return (
    <Card id="supplement-card">
      <Card.Content>
        <Container flexbox={true} classNames={['align-items-end mb-5']}>
          <Heading id="heading" as="T20" text={string_table.SUPPLEMENTS} />
        </Container>
        <>
          {supplementBenefits?.map((supplementBenefit, index) => {
            const supplementName = supplementBenefit?.friendlyName;
            return (
              <Card.Content key={index}>
                <Card.Title>{supplementName}</Card.Title>
                <Container flexbox={true} classNames={['flex-row-reverse']}>
                  <Text
                    id="price"
                    textWeight="bold"
                    textSize="extra-large"
                    text={string_table.SUPPLEMENT_PRICE_INCLUDED}
                  />
                </Container>
                <Benefits
                  subscription={subscription}
                  isSupplementBenefits={true}
                  supplementBenefits={supplementBenefit}
                />
              </Card.Content>
            );
          })}
        </>
      </Card.Content>
    </Card>
  );
};
