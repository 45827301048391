import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Icon, Text } from '@legalshield/adonis-ux-framework';
import cn from 'classnames';

import { AvailableMerchandizedTier } from '../Payments/utils/getUpgradeableSubscriptions';
import { useProductBrochureByProductId } from '../Payments/utils/getProductBrochureByProductId';
import { ISubscriptionResource } from '../Payments/hooks/utils/subscription-utils';
import { useAllBenefitsById } from '../Payments/utils/getAllBenefitsById';

import './AvailablePlan.scss';

export const TIER_SUBTITLES = {
  Basic: 'Perfect for single people',
  Preferred: 'Ideal for families',
  Premium: 'Comprehensive protection for the entire family',
};

export const TIER_TEXT = {
  Basic: '',
  Preferred:
    'Speak with a lawyer on an unlimited number of personal legal issues and get enhanced legal support for your family.',
  Premium:
    'Speak with a lawyer on an unlimited number of personal legal issues with our most robust suite of legal services at your disposal.',
};

interface AvailablePlanProps {
  buttonIsDisabled?: boolean;
  showPlanExplanation?: boolean;
  showIncludesFeatures?: boolean;
  buttonOnClick?: () => void;
  classNames?: cn.Argument;
  tier?: AvailableMerchandizedTier;
  subscription?: ISubscriptionResource;
}

const AvailablePlan: FC<AvailablePlanProps> = ({
  buttonIsDisabled,
  showPlanExplanation,
  showIncludesFeatures,
  buttonOnClick,
  classNames,
  tier,
  subscription,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isSelected = false;

  const { data: product } = useProductBrochureByProductId(tier?.merchandizedProduct.id);

  const productTier = product?.tier?.find((t) => t.name === tier.tier);

  const title =
    productTier?.friendlyNames?.find((fn) => fn.locale === 'en')?.name ??
    product?.friendlyNames?.find((fn) => fn.locale === 'en')?.name;

  const price = tier.merchandizedProduct?.merchandizedTiers
    ?.find((t) => t.name === tier.tier)
    ?.prices?.find((p) => p.billingPeriod === subscription.billingFrequency.period).renewalListPrice;

  const { data: allBenefits } = useAllBenefitsById(
    tier.merchandizedProduct.id,
    tier.merchandizedProduct.productVersion.toString(),
    tier.tier,
  );

  return (
    <Card classNames={['available-plan__card', isSelected && 'disabled_card', classNames]}>
      <Card.Content classNames={['available-plan__content']}>
        <Text text={title || '-'} classNames={['available-plan__title']} />
        <Text text={TIER_SUBTITLES[tier.tier] || ''} classNames={['available-plan__subtitle', 'mb-4']} />
        <div className="available-plan__price_wrapper">
          <Text text={'$' + price} classNames={['available-plan__price']} />
          <Text text="/mo." classNames={['available-plan__price_quarter']} />
        </div>
        <Button
          classNames={['mt-5']}
          variant="primary"
          label={string_table.UPGRADE_CTA_TEXT}
          disabled={buttonIsDisabled}
          onClick={buttonOnClick}
        />

        <Text text={TIER_TEXT[tier.tier] || ''} classNames={['mt-8', 'available-plan__mobile_plan_description']} />

        <Container classNames={[isExpanded ? '' : 'hidden_content_mobile', 'available-plan__footer_link_bg_color']}>
          <hr className="available-plan__modal_divider" />
          <Text text="Legal services" classNames={['mb-4']} textWeight="semibold" />
          {showIncludesFeatures && (
            <div className="available-plan__custom_container">
              <Icon name="warning_circle_check_filled" />
              <p className="available-plan__custom_container_text">
                Includes all <b>{'featureType'}</b> features
              </p>
            </div>
          )}
          {showPlanExplanation && (
            <p className="available-plan__list_text_explanation">
              Document review, document creation, and legal letters for the following:
            </p>
          )}

          <>
            {allBenefits?.productBenefits.benefits.map((benefit) => (
              <div className="available-plan__list" key={benefit.id}>
                <Icon name="interface_check" />
                <p className="available-plan__list_text">{benefit.friendlyName}</p>
              </div>
            ))}
          </>
        </Container>
      </Card.Content>

      <Container
        background="none"
        classNames={[
          isExpanded ? '' : 'hidden_content_mobile',
          'available-plan__footer_link_container',
          'available-plan__footer_link_bg_color',
        ]}
      >
        {/* to uncomment when this work is ready to go
        <Link to={'#'} className="available-plan__footer_link">
          <Container
            background="none"
            alignItems="center"
            flexbox
            flexDirection="row"
            classNames={['pt-4', 'available-plan__footer_link_bg_color']}
          >
            <Text
              text="View additional services"
              textSize="medium"
              textWeight="semibold"
              classNames={['available-plan__footer_link_text']}
            />
            <Icon name="arrow_caret_right_sm" color="BRAND200" />
          </Container>
        </Link> */}
        <Link
          className="available-plan__footer_link"
          target="_blank"
          to={
            tier.tier === 'Preferred'
              ? 'https://contracts.legalshield.com/EmailContracts/LSPFLP24.724.pdf'
              : 'https://contracts.legalshield.com/EmailContracts/LSPLP24.724.pdf'
          }
        >
          <Container
            background="none"
            alignItems="center"
            flexbox
            flexDirection="row"
            classNames={['pt-4', 'available-plan__footer_link_bg_color']}
          >
            <Text
              text={string_table.AVAILABLE_PLAN_VIEW_CONTRACT}
              textSize="medium"
              textWeight="semibold"
              classNames={['available-plan__footer_link_text']}
            />
            <Icon name="arrow_caret_right_sm" color="BRAND200" />
          </Container>
        </Link>
      </Container>

      <Container
        background="none"
        classNames={[
          'pt-4',
          'available-plan__footer_link_bg_color',
          'available-plan__hide_content_desktop',
          'footer_link_container__mobile',
          'see_details_link__mobile',
        ]}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Icon name="arrow_caret_right_sm" color="BRAND200" />
        {isExpanded ? (
          <Text
            text={string_table.AVAILABLE_PLAN_SEE_LESS}
            textSize="medium"
            textWeight="semibold"
            classNames={['available-plan__footer_link_text']}
          />
        ) : (
          <Text
            text={string_table.AVAILABLE_PLAN_SEE_DETAILS}
            textSize="medium"
            textWeight="semibold"
            classNames={['available-plan__footer_link_text']}
          />
        )}
      </Container>
    </Card>
  );
};

export default AvailablePlan;
