import React, { Fragment } from 'react';
import { Grid, GridCol, GridRow, Text } from '@legalshield/adonis-ux-framework';
import { ProductBenefit } from '@legalshield/frontend-commons/dist/sdk/eligibilities';

type SupplementBenefitsProps = {
  supplementBenefits: ProductBenefit | undefined;
};

export const SupplementBenefits = ({ supplementBenefits }: SupplementBenefitsProps) => {
  if (!supplementBenefits?.benefits || supplementBenefits == undefined) return <Fragment />;

  return (
    <Grid id="supplement-benefits">
      <GridRow variant="plain">
        <GridCol>
          {supplementBenefits.benefits.map((benefit, index) => (
            <GridCol key={index}>
              <Text id={`supplement-benefit-${index}`} text={`*${benefit.friendlyName}`} />
              <br />
            </GridCol>
          ))}
        </GridCol>
      </GridRow>
    </Grid>
  );
};
