export function stringFormat(baseString: string, inputs: string[]): string {
  return baseString.replace(/{(\d+)}/g, function (match, number) {
    return typeof inputs[number] != 'undefined' ? inputs[number] : match;
  });
}

export function populateTemplateString(baseString: string, inputs: { [key: string]: string }): string {
  if (!baseString) return '';
  return baseString.replace(/\$\{(\w+)\}/g, function (match, key) {
    return typeof inputs[key] != 'undefined' ? inputs[key] : match;
  });
}
