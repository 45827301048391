import { Realm } from '@legalshield/frontend-commons/dist/sdk/identities';

const checkSubType = (type: Realm): boolean => pplsi.authNPayload?.sub_type === type;

const isUser = (): boolean => checkSubType(Realm.User);
const isMachine = (): boolean => checkSubType(Realm.Machine);
const isInternal = (): boolean => checkSubType(Realm.Internal);
const isPartner = (): boolean => checkSubType(Realm.Partner);
const isGroupAdmin = (): boolean => checkSubType(Realm.GroupAdmin);

const getRealm = (): Realm => {
  const realmGroups: Array<{ domains: string[]; realm: Realm }> = [
    { domains: ['legalshieldinternal.com', 'pplsipeople.com'], realm: Realm.Internal },
    { domains: ['legalshieldatwork.com', 'shieldatwork.com'], realm: Realm.GroupAdmin },
    { domains: ['legalshieldproviders.com', 'pplsipartners.com', 'shieldproviders.com'], realm: Realm.Partner },
  ];

  const realm = realmGroups.find((group) => group.domains.some((domain) => window.location.hostname.endsWith(domain)));

  return realm ? realm.realm : Realm.User;
};

export default {
  getRealm,
  isGroupAdmin,
  isInternal,
  isMachine,
  isPartner,
  isUser,
};
