// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
  left: -80px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Payments/Subscriptions/LinkMembershipModal/LinkMembershipModal.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,sBAAA;AACF","sourcesContent":[".tippy-box[data-placement^=top]>.tippy-arrow {\n  bottom: 0;\n  left: -80px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
