import React, { createContext, useReducer, Dispatch, useContext, FC } from 'react';
import ModalAlert from '../ModalAlert';

type ModalAlertState = {
  displayed: boolean;
  message: string;
  title: string;
};

type ModalAlertAction = DisplayModalAlertAction | DismissModalAlertAction;

type ModalAlertContextType = {
  modalAlertState: ModalAlertState;
  modalAlertDispatch: Dispatch<ModalAlertAction>;
};

type DisplayModalAlertAction = {
  type: 'DISPLAY';
  message: string;
  title: string;
};

type DismissModalAlertAction = {
  type: 'DISMISS';
};

type ModalAlertProviderProps = {
  children: React.ReactNode;
};

export const modalAlertReducer = (modalAlertState: ModalAlertState, action: ModalAlertAction): ModalAlertState => {
  switch (action.type) {
    case 'DISPLAY':
      return {
        displayed: true,
        message: action.message,
        title: action.title,
      };
    case 'DISMISS':
      return {
        displayed: false,
        message: '',
        title: '',
      };

    default:
      return modalAlertState;
  }
};

export const ModalAlertProvider: FC<ModalAlertProviderProps> = ({ children }) => {
  const initialState: ModalAlertState = {
    displayed: false,
    message: '',
    title: '',
  };

  const [modalAlertState, modalAlertDispatch] = useReducer(modalAlertReducer, initialState);

  return (
    <AlertsContext.Provider value={{ modalAlertDispatch, modalAlertState }}>
      {modalAlertState?.displayed && <ModalAlert title={modalAlertState?.title} message={modalAlertState.message} />}
      {children}
    </AlertsContext.Provider>
  );
};

export const AlertsContext = createContext<ModalAlertContextType>({
  modalAlertDispatch: () => undefined,
  modalAlertState: {
    displayed: false,
    message: '',
    title: '',
  },
});

export const useModalAlert = () => {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error('useModalAlert must be used within ModalAlertProvider');
  }

  return context;
};
