import React, { useEffect } from 'react';
import { Loader, Text, Container, Toggle, useLoader } from '@legalshield/adonis-ux-framework';
import { useUpdateMultifactorSettings } from '../../../hooks/useIdentity';

interface SettingsProps {
  factorCount: number;
  hasVerifiedFactor: boolean;
  multiFactorType: boolean;
}

const Settings: React.FC<SettingsProps> = ({ factorCount, hasVerifiedFactor, multiFactorType }: SettingsProps) => {
  const loader = useLoader();
  const { mutate: settingMutate, isError, isLoading, isSuccess } = useUpdateMultifactorSettings();

  function updateSettings() {
    loader.Loading();
    settingMutate({ multifactorType: !multiFactorType });
  }

  useEffect(() => {
    if (isError) {
      loader.Error(string_table.MFA_SETTINGS_ERRORUPDATE);
    }
    if (isLoading) {
      loader.Loading();
    }
    if (isSuccess) {
      loader.Success(string_table.MFA_SETTINGS_UPDATESUCCESSFUL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, isLoading]);

  return (
    <>
      <Container flexbox justifyContent="center">
        <Text as="p" textWeight="bold" text={string_table.MFA_SETTINGS_TURNON} />
        <Toggle
          classNames={['ml-10']}
          disabled={!hasVerifiedFactor || factorCount === 0}
          onClick={updateSettings}
          isChecked={multiFactorType && factorCount > 0}
          switchSize={'large'}
        />
      </Container>
      <Loader loaderState={loader.loaderState} />
    </>
  );
};

export default Settings;
