import React, { FC, useEffect, useState } from 'react';

import { Card, Divider, useLoader } from '@legalshield/adonis-ux-framework';
import { useNavigate } from 'react-router-dom';

import RealmUtility from '../../utils/realm';
import { EditContainer } from '../Controls/EditContainer';
import { Layout } from '../Layout/Layout';
import { useGetFactors, useGetIdentity } from '../../hooks/useIdentity';
import { useGetProfile } from '../../hooks/useProfile';

const Security: FC = () => {
  const loader = useLoader();
  const navigate = useNavigate();
  const [isGroupAdmin] = useState(RealmUtility.isGroupAdmin());
  const [isPartner] = useState(RealmUtility.isPartner());

  const {
    data: pData,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
  } = useGetProfile();

  const {
    data: sData,
    isLoading: isSecurityLoading,
    isSuccess: isSecuritySuccess,
    isError: isSecurityError,
  } = useGetIdentity();

  const { data: mfa } = useGetFactors();
  const mfaText =
    mfa?.settings?.multifactorType === false ? string_table.SECURITY_MFA_OFF : string_table.SECURITY_MFA_ON;

  useEffect(() => {
    if (isProfileError || isSecurityError) {
      loader.Error(string_table.ALERT_ERROR);
    }
    if (isProfileLoading || isSecurityLoading) {
      loader.Loading();
    }
    if (isSecuritySuccess && isProfileSuccess) {
      loader.Blank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileLoading, isProfileError, isProfileSuccess, isSecuritySuccess, isSecurityError, isSecurityLoading]);

  const redirect = (path: string) => {
    loader.Blank();
    navigate(`${path}`);
  };

  const crumbs = [
    { label: string_table.OVERVIEW_BREADCRUMB, link: '/overview' },
    { label: string_table.SECURITY_TITLE, link: '/security' },
  ];

  return (
    <Layout crumbs={crumbs} title={string_table.SECURITY_TITLE} subtitle={string_table.SECURITY_DESCRIPTION}>
      <Divider classNames={['py-4']} />
      <Card>
        <Card.Content>
          <Card.Title>{string_table.SECURITY_SIGN_IN}</Card.Title>
          <EditContainer
            id="emailContainer"
            title={string_table.SECURITY_EMAIL_TITLE}
            data={sData?.emails.join(', ')}
          />
          {(isPartner || isGroupAdmin) && !sData?.hasPassword ? (
            <EditContainer
              id="pswrdNewPartnerContainer"
              title={string_table.SECURITY_PASSWORD}
              buttonText={string_table.SECURITY_SET_PASSWORD}
              data={string_table.SECURITY_NO_PASSWORD}
              onClick={() => redirect('/security/password')}
            />
          ) : (
            <EditContainer
              id="pswrdContainer"
              title={string_table.SECURITY_PASSWORD}
              data="••••••••"
              onClick={() => redirect('/security/password')}
            />
          )}
          <EditContainer
            id="mfaContainer"
            title={string_table.SECURITY_MFA_VERIFICATION}
            data={mfaText}
            onClick={() => redirect('/mfa')}
          />
        </Card.Content>
      </Card>
    </Layout>
  );
};

export default Security;
