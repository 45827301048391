import { FriendlyName, ProductTier, Product as ProductBrochure } from '@legalshield/frontend-commons/dist/sdk/products';
import Cookies from 'js-cookie';
import { ISubscriptionResource } from '../components/Payments/hooks/utils/subscription-utils';
import { OfferItem } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { IEntitlement } from '../interfaces/entitlement.interface';

const locale = Cookies.get('market') || '';

export const getLocale = () => {
  const language = locale?.split('-')[0]?.toLowerCase();
  return language || 'en';
};

export const getFriendlyNameByLocale = (friendlyNames: FriendlyName[], preferredLocale: string) => {
  if (!friendlyNames)
    return '';

  return friendlyNames.find(fn => fn.locale?.toLowerCase() === preferredLocale)?.name ||
    friendlyNames.find(fn => fn.locale?.toLowerCase() === 'en')?.name ||
    friendlyNames[0]?.name ||
    '';
};

const currentLocale = getLocale();

export const getFriendlyProductNameFromSubscription = (subscription: ISubscriptionResource) => {
  const offerItems = subscription?.offer?.offerItems ?? [];
  return getFriendlyProductName(subscription?.productBrochure,
    getSubscriptionMatchingTier(subscription?.productBrochure?.tier, offerItems),
    offerItems.some(oi => oi.type === 'SUPPLEMENT'));
};

export const getFriendlyProductNameFromEntitlement = (entitlement: IEntitlement) => {
  return getFriendlyProductName(entitlement?.productBrochure,
    findMatchingTier(entitlement?.productBrochure?.tier, entitlement?.product?.tier),
    Object.keys(entitlement?.product?.supplements ?? {}).length > 0);
};

function getFriendlyProductName(productBrochure: ProductBrochure, matchingTier: ProductTier, hasSupplement: boolean): string {
  if (!productBrochure)
    return '';

  const friendlyTierName = getFriendlyProductTierName(matchingTier);
  const productFriendlyName = getFriendlyNameByLocale(productBrochure.friendlyNames, currentLocale);
  return combineDistinct(productFriendlyName, friendlyTierName) + getSupplementText(hasSupplement);
};

function getSubscriptionMatchingTier(tiers: ProductTier[], offerItems: OfferItem[]): ProductTier {
  const offerItem = offerItems.find(oi => oi.type === 'PRODUCT') || offerItems.find(() => true);
  return !!offerItem ? findMatchingTier(tiers, offerItem.productDetail?.productTier) : null;
};

function findMatchingTier(tiers: ProductTier[], tierName: string): ProductTier | undefined {
  return tiers?.find(t => t.name === tierName);
};

/**
 * Get the friendly name of a tier in the current locale, fall back to the tier name and finally an empty string.
 * @param tier Tier from which the friendly name will be derived.
 * @returns Tier friendlyName, name, or an empty string.
 */
function getFriendlyProductTierName(tier: ProductTier): string {
  if (!tier)
    return '';

  const friendlyName = getFriendlyNameByLocale(tier.friendlyNames, currentLocale);
  return !isNullOrWhitespace(friendlyName) ? friendlyName : tier.name || '';
};

function getSupplementText(hasSupplement: boolean): string {
  return hasSupplement ? ' w/ Supplements' : '';
};

// https://stackoverflow.com/a/32800728/4119585
function isNullOrWhitespace(input: string): boolean {
  return !input || !input.trim();
};

function combineDistinct(string1: string, string2: string) {
  return string2.includes(string1)
    ? string2.trim()
    : `${string1} ${string2}`.trim();
};
