import { SubscriptionResource } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { Api as ProductsApi, Product } from '@legalshield/frontend-commons/dist/sdk/products';

// subscription-utils.ts

/*
 * Setting products onto our subscription resource
 */

export interface ISubscriptionResource extends SubscriptionResource {
  productBrochure: Product;
  productId: string;
}

const productsApi = new ProductsApi();

const setProductId = (subscription: ISubscriptionResource) => {
  const productIds = subscription.offer.offerItems.map((offerItem) => offerItem?.productDetail?.productId);

  productIds.map((productId) => {
    if (!productId) return;
    subscription.productId = productId;
  });
};

const setProductBrochure = async (subscription: ISubscriptionResource) => {
  const { data: productBrochure } = await productsApi.v1.productsGetProduct(subscription.productId);
  subscription.productBrochure = productBrochure;
};

export const setSubscriptionProduct = async (subscription: ISubscriptionResource) => {
  setProductId(subscription);
  await setProductBrochure(subscription);
};
