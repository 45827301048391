import { Eligibilities } from '@legalshield/frontend-commons';

import { useQuery } from "@tanstack/react-query"

const eligibilities = new Eligibilities.Api();

export const useAllBenefitsById = (productId: string, productVersion: string, tier: string) => {
    return useQuery<Eligibilities.EligibilitiesResponse>({
        enabled: !!productId,
        queryFn: async () => {
            const response = await eligibilities.v1.benefitsEligibilitiesBenefitsEligibilitiesByProduct2({
                "id": productId,
                "productVersion": productVersion,
                "tier": tier
            }, 
            { 'includeAllSupplements': false }, 
            { headers: { 'Accept-Language': 'en' } 
        });
            return response.data;
        },
        queryKey: ['allBenefits', productId, productVersion, tier],
    });
}