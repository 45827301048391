import { Product, Api as ProductsApi } from '@legalshield/frontend-commons/dist/sdk/products';
import { useQuery } from '@tanstack/react-query';

const productsApi = new ProductsApi();

export const useProductBrochureByProductId = (productId: string) => {
    
    return useQuery<Product>({
        enabled: !!productId,
        queryFn: async () => {
            const response = await productsApi.v1.productsGetProduct(productId);
            return response.data;
        },
        queryKey: ['productBrochure', productId],
    });
};