import React, { FC, useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Grid,
  GridCol,
  GridRow,
  Loader,
  Text,
  useLoader,
} from '@legalshield/adonis-ux-framework';
import { Route, Routes } from 'react-router-dom';

import Multifactor from '../Multifactor/Multifactor';
import { Password } from '../Security/Password/Password';

const Setup: FC = () => {
  const p = new URLSearchParams(window.location.search);
  const app = p.has('app') ? p.get('app') : 'accounts';
  const name = p.has('name') ? p.get('name') : app;

  let label = '';
  let requiresMFT = false;
  let requiresPWD = false;
  if (window.location.pathname.includes('/mfa')) {
    label = string_table.SETUP_MFA_REQUIRED;
    requiresMFT = true;
  } else if (window.location.pathname.includes('/password')) {
    label = string_table.SETUP_PASSWORD_REQUIRED;
    requiresPWD = true;
  }

  const navClicked = () => {
    const path = p.has('path') ? p.get('path') : '';
    const pathString = path.startsWith('/') ? path : '/' + path;
    const port = p.has('port') ? p.get('port') : '';
    const portString = port ? ':' + port : '';

    const appUrl = 'https://' + app + '.' + pplsi.realmDomain + portString + pathString;
    const loginUrl =
      'https://login.' +
      pplsi.realmDomain +
      '/?app=' +
      app +
      '&path=' +
      encodeURIComponent(pathString) +
      '&port=' +
      port;

    if (!backToEnabled) return;

    if (requiresMFT && !(pplsi.authNPayload as any).mft) {
      window.location.href = loginUrl;
    } else if (requiresPWD /* Check for password in payload */) {
      window.location.href = loginUrl;
    } else {
      window.location.href = appUrl;
    }
  };

  const [backToEnabled, setBackToEnabled] = useState(false);

  const loader = useLoader();

  return (
    <>
      <Grid>
        <GridRow variant="center">
          <GridCol>
            <Container classNames={['mt-3']}>
              <Alert severity="information" bold={false}>
                <>
                  <Text as="p" classNames={['mt-3']} text={label.replace('%name%', name)} />
                  <Container classNames={['mt-4']} flexbox background="none" justifyContent="center">
                    <Button
                      disabled={!backToEnabled}
                      label={string_table.SETUP_BACK_TO.replace('%name%', name)}
                      variant="primary"
                      onClick={() => navClicked()}
                    />
                  </Container>
                </>
              </Alert>
            </Container>
          </GridCol>
        </GridRow>
      </Grid>

      <Routes>
        <Route path={'mfa'} element={<Multifactor operationEnabled={setBackToEnabled} />} />

        <Route
          path={'password'}
          element={
            <Grid>
              <GridRow variant="center">
                <GridCol>
                  <Password />
                  <Loader loaderState={loader.loaderState} />
                </GridCol>
              </GridRow>
            </Grid>
          }
        />
      </Routes>
    </>
  );
};

export default Setup;
