export class NamePresenter {
  firstName: string;
  middleName: string;
  lastName: string;
  version: number;

  constructor(firstName: string, middleName: string, lastName: string, version: number) {
    this.firstName = firstName || '';
    this.middleName = middleName || '';
    this.lastName = lastName || '';
    this.version = version;
  }
}
