import React, { FC } from 'react';
import { Container, Icon, Text } from '@legalshield/adonis-ux-framework';

import './CardLink.scss';

interface CardLinkProps {
  ids?: boolean;
  url?: string;
  text?: string;
  iconColor?: string;
}

const CardLink: FC<CardLinkProps> = ({ ids, url, text = '', iconColor }) => (
  <a
    className="card-link"
    href={url}
    style={ids ? { color: '#3050F0' } : { color: '#8231d4' }}
    target="_blank"
    rel="noreferrer"
  >
    <Container alignItems="center" flexbox flexDirection="row" classNames={['pt-4']}>
      <Text text={text} textSize="medium" textWeight="semibold" />
      <Icon name="arrow_right_lg" color={iconColor} classNames={['ml-2']} />
    </Container>
  </a>
);

export default CardLink;
