import React from 'react';
import { Button, Container, Text } from '@legalshield/adonis-ux-framework';

export interface ButtonBarProps {
  description?: string;
  customSaveLabel?: string;
  customCancelLabel?: string;
  disableCancel?: boolean;
  disableSave?: boolean;
  onCancel?(arg?: unknown): void;
  onSave?(arg?: unknown): unknown;
  id: string;
  alignLeft?: boolean;
}

// eslint-disable-next-line react/display-name
export const ButtonBar: React.FC<ButtonBarProps> = React.memo(
  ({
    description,
    customSaveLabel = '',
    disableSave = false,
    onSave = null,
    customCancelLabel = '',
    disableCancel = false,
    onCancel = null,
    id = null,
    alignLeft = false,
  }: ButtonBarProps) => {
    return (
      <Container id={id}>
        <Container
          flexbox
          justifyContent={!alignLeft ? 'flex-end' : 'flex-start'}
          classNames={!alignLeft ? ['p-4'] : ['pt-4', 'pr-4', 'pb-4']}
        >
          {onCancel && (
            <Button
              classNames={['ml-3']}
              variant="secondary"
              label={customCancelLabel != '' ? customCancelLabel : string_table.CANCEL}
              disabled={disableCancel}
              onClick={onCancel}
            />
          )}
          {onSave && (
            <Button
              classNames={alignLeft ? ['ml-2'] : ['ml-3']}
              variant="primary"
              label={customSaveLabel != '' ? customSaveLabel : string_table.SAVE}
              disabled={disableSave}
              onClick={onSave}
            />
          )}
        </Container>
        {description && (
          <Container flexbox justifyContent="flex-end" classNames={['p-4']}>
            <Text text={description} textSize="small" />
          </Container>
        )}
      </Container>
    );
  },
);
