import React, { useState, useEffect } from 'react';
import { useGetPaymentMethod } from '../usePaymentMethods';
import { SubscriptionStatus } from '@legalshield/frontend-commons/dist/sdk/subscriptions';
import { isBankAccount } from '../../utils/utils';

export const usePaymentLastFour = (paymentMethodId: string, subscriptionStatus: SubscriptionStatus) => {
  const { data: paymentMethod } = useGetPaymentMethod(paymentMethodId);
  const [lastFourDigits, setLastFourDigits] = useState<string | null>(null);

  useEffect(() => {
    if (subscriptionStatus === SubscriptionStatus.PRECANCEL && paymentMethod) {
      const lastFour = isBankAccount(paymentMethod)
        ? (paymentMethod?.accountNumber || paymentMethod?.bankAccount?.accountNumber)?.slice(-4)
        : (paymentMethod?.cardNumber || paymentMethod?.creditCard?.cardNumber)?.slice(-4);
      setLastFourDigits(lastFour);
    }
  }, [subscriptionStatus, paymentMethod]);

  return lastFourDigits;
};
