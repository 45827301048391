export class DateOfBirthPresenter {
  dateOfBirth: string;
  formattedDateOfBirth: string;
  version: number;

  constructor(dateOfBirth: string, formattedDateOfBirth: string, version: number) {
    this.dateOfBirth = dateOfBirth || '';
    this.formattedDateOfBirth = formattedDateOfBirth || '';
    this.version = version;
  }
}
