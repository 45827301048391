import { SDKHeaders } from '@legalshield/frontend-commons';
import {
  AddEmailRequest,
  AddPhoneNumberRequest,
  Api,
  CommunicationPreferencesResource,
  OptInRequest,
  RemoveEmailRequest,
  RemovePhoneNumberRequest,
  AddAddressRequest,
  UpdateAddressRequest,
  UpdateDateOfBirthRequest,
  UpdateLocaleRequest,
  UpdateNameRequest,
  UpdatePreferredNameRequest,
  UpdateTitleRequest,
  UpdatePronounsRequest,
} from '@legalshield/frontend-commons/dist/sdk/profiles';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { validateStatusResponse } from '../utils/utils';

const profileApi = new Api();

const QUERY_PROFILE = 'query-profile';

export const useGetProfile = () => {
  return useQuery([QUERY_PROFILE], async () => (await profileApi.v1.profilesFindByIdentityId(authNPayload.sub)).data);
};

function defaultOptions(queryClient: QueryClient, queryToInvalidate?: string) {
  return {
    onError: (err) => {
      console.log(`error ${err}`);
    },
    onSettled: () => {
      if (queryToInvalidate) {
        queryClient.invalidateQueries([queryToInvalidate]);
      }
    },
  };
}

export const useEditAddress = (version: number, isAdd: boolean) => {
  return useMutation(
    ['editAddress'],
    isAdd
      ? async (payload: AddAddressRequest) =>
          await profileApi.v1.addressAddAddress(
            authNPayload.sub,
            payload,
            SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
          )
      : async (payload: UpdateAddressRequest) =>
          await profileApi.v1.addressUpdateAddress(
            authNPayload.sub,
            { type: payload.type },
            payload,
            SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
          ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateAddress = (version: number) => {
  return useMutation(
    ['updateAddress'],
    async (payload: UpdateAddressRequest) =>
      await profileApi.v1.addressUpdateAddress(
        authNPayload.sub,
        { type: payload.type },
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateDob = (version: number) => {
  return useMutation(
    ['updateDob'],
    async (payload: UpdateDateOfBirthRequest) =>
      await profileApi.v1.dateOfBirthUpdateDateOfBirth(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateEmail = (version: number) => {
  return useMutation(
    ['updateEmail'],
    async (payload: any) =>
      await profileApi.v1.emailUpdateEmail(
        authNPayload.sub,
        { address: payload.oldAddress },
        payload.req,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useAddEmail = (version: number) => {
  return useMutation(
    ['addEmail'],
    async (payload: AddEmailRequest) =>
      await profileApi.v1.emailAddEmail(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useRemoveEmail = (version: number) => {
  return useMutation(
    ['removeEmail'],
    async (payload: RemoveEmailRequest) =>
      (
        await profileApi.v1.emailRemoveEmail(
          authNPayload.sub,
          payload,
          SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
        )
      ).data,
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateLocale = (version: number) => {
  return useMutation(
    ['updateLocale'],
    async (payload: UpdateLocaleRequest) =>
      await profileApi.v1.localeUpdateLocale(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdatePhone = (version: number) => {
  return useMutation(
    ['updatePhone'],
    async (payload: any) =>
      await profileApi.v1.phoneNumberUpdatePhoneNumber(
        authNPayload.sub,
        {
          phoneNumber: payload.oldNumber,
          type: payload.oldType,
        },
        payload.req,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useAddPhone = (version: number) => {
  return useMutation(
    ['addPhone'],
    async (payload: AddPhoneNumberRequest) =>
      await profileApi.v1.phoneNumberAddPhoneNumber(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useRemovePhone = (version: number) => {
  return useMutation(
    ['removePhone'],
    async (payload: RemovePhoneNumberRequest) =>
      await profileApi.v1.phoneNumberRemovePhoneNumber(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useSmsOptIn = (version: number) => {
  return useMutation(
    ['smsOptIn'],
    async (payload: OptInRequest) =>
      await profileApi.v1.marketingPreferencesSmsOptIn(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateCommunication = (version: number) => {
  return useMutation(
    ['updateCommunication'],
    async (payload: CommunicationPreferencesResource) =>
      await profileApi.v1.communicationPreferencesUpdateCommunicationPreferences(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateSpecificCommunication = (communicationPreference: string, identityId: string) => {
  return useMutation(
    ['updateSpecificCommunication'],
    async (payload: Record<string, boolean>) =>
      await profileApi.v1.communicationPreferencesUpdateCommunicationPreference(
        identityId,
        communicationPreference,
        payload,
        null,
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateSpecificCommunicationByEmail = (communicationPreference: string, emailAddress: string) => {
  return useMutation(
    ['updateSpecificCommunication'],
    async (payload: Record<string, boolean>) =>
      await profileApi.v1.communicationPreferencesUpdateCommunicationPreferenceByEmail(
        emailAddress,
        communicationPreference,
        payload,
        null,
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateName = (version: number) => {
  return useMutation(
    ['updateName'],
    async (payload: UpdateNameRequest) =>
      await profileApi.v1.nameUpdateName(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdatePreferredName = (version: number) => {
  return useMutation(
    ['updatePreferredName'],
    async (payload: UpdatePreferredNameRequest) =>
      await profileApi.v1.preferredNameUpdatePreferredName(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdateTitle = (version: number) => {
  return useMutation(
    ['updateTitle'],
    async (payload: UpdateTitleRequest) =>
      await profileApi.v1.titleUpdateTitle(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};

export const useUpdatePronouns = (version: number) => {
  return useMutation(
    ['updatePronouns'],
    async (payload: UpdatePronounsRequest) =>
      await profileApi.v1.pronounsUpdatePronouns(
        authNPayload.sub,
        payload,
        SDKHeaders.getVersionConfig(version, true, validateStatusResponse()),
      ),
    defaultOptions(useQueryClient(), QUERY_PROFILE),
  );
};
