import { useMutation } from '@tanstack/react-query';
import { Profiles, Provision, Subscriptions } from '@legalshield/frontend-commons';
import { AvailableMerchandizedTier } from './getUpgradeableSubscriptions';

// This asynchronous function sends a request to update a subscription's tier by calling an API endpoint
export const updateSubscriptionTier = async (
  subscription: Subscriptions.SubscriptionResource | undefined,
  selectedTier: AvailableMerchandizedTier,
  profile: Profiles.ProfileResource | undefined,
) => {
  if (!subscription) {
    throw new Error('Subscription not found');
  }

  // Constructing a ProvisionRequest object containing details about the new offer and subscription
  const data: Provision.ProvisionRequest = {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    dateOfBirth: profile?.dateOfBirth,
    email: profile?.emails?.find((e) => e.preferred)?.address || profile?.emails?.[0]?.address || '',
    billingDivision: subscription.billingDivision,
    billingFrequency: subscription.billingFrequency,
    nextBillOn: subscription.nextBillOn,
    offers: [
      {
        id: selectedTier.offer.id!,
        products: [
          {
            currency: Provision.Currency.USD,
            family: selectedTier.merchandizedProduct.productFamily,
            id: selectedTier.merchandizedProduct.id,
            name: selectedTier.offer.commonName,
            price: selectedTier.price.renewalListPrice,
            type: selectedTier.merchandizedProduct.productType,
            tier: selectedTier.tier,
            version: (selectedTier.merchandizedProduct.productVersion || 1).toString(),
          },
        ],
      },
    ],
    paymentMethodId: subscription.paymentMethodId,
    administrativeArea: subscription.administrativeArea || profile?.addresses?.[0]?.administrativeArea,
    postalCode: subscription.postalCode || profile?.addresses?.[0]?.postalCode,
    source: subscription.source,
    startedOn: subscription.startedOn,
    subscriptionId: subscription.id,
  };

  // Sending a POST request to the API endpoint to submit the subscription changes using axios
  const response = await (new Provision.Api()).v1.provisionSubmitPlanChanges(subscription.ownerId, data);

  return response.data;
};

// This hook leverages the useMutation hook to manage the side-effect of updating the subscription tier in an asynchronous manner
export const useUpdateSubscriptionTier = (subscription?: Subscriptions.SubscriptionResource) =>
  // It uses useMutation to manage the mutation process, including invoking the updateSubscriptionTier function when the mutation is triggered
  useMutation({
    mutationFn: ({
      selectedTier,
      profile,
    }: {
      selectedTier: AvailableMerchandizedTier;
      profile: Profiles.ProfileResource;
    }) => updateSubscriptionTier(subscription, selectedTier, profile),
    mutationKey: ['update-subscription-tier', subscription?.id || ''],
  });
