// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upgrade-plan__container {
  gap: 20px;
}
.upgrade-plan__row-width {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/UpgradePlanSkeleton/AvailablePlanSkeleton.scss"],"names":[],"mappings":"AACE;EACE,SAAA;AAAJ;AAEE;EACE,OAAA;AAAJ","sourcesContent":[".upgrade-plan {\n  &__container {\n    gap: 20px;\n  }\n  &__row-width {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
