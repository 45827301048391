import React, { FC, useState } from 'react';
import { Alert } from '@legalshield/adonis-ux-framework';

import { isUpgradeSuccessful } from '../../Payments/utils/getUpgradeableSubscriptions';

const ProvisioningBanner: FC = () => {
  const userId = pplsi?.authNPayload?.sub || '';
  const [showBanner, setShowBanner] = useState<boolean>(isUpgradeSuccessful(userId));

  const handleDismiss = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Alert
      classNames={['mt-4']}
      dismissible
      onDismiss={handleDismiss}
      icon="calendar_clock"
      title={string_table.UPGRADE_PROVISIONING_BANNER_TITLE}
    >
      {string_table.UPGRADE_PROVISIONING_BANNER_TEXT}
    </Alert>
  );
};

export default ProvisioningBanner;
