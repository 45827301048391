import React, { useContext, useEffect } from 'react';
import { Alert, Severity, Snackbar } from '@legalshield/adonis-ux-framework';
import { BannerAlertContext } from './context/BannerAlertContext';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '../../../utils/usePrevious';

type AlertsProps = {
  message: string;
  severity: Severity | undefined;
  title?: string;
  alertType?: 'alert' | 'snackbar';
};

const BannerAlert = ({ message, severity, title = '', alertType = 'alert' }: AlertsProps) => {
  const { bannerAlertDispatch } = useContext(BannerAlertContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const previousPath = usePrevious(currentPath);

  const handleDismiss = () => {
    bannerAlertDispatch({ type: 'DISMISS' });
  };

  useEffect(() => {
    if (previousPath && previousPath !== currentPath) {
      handleDismiss();
    }
  }, [currentPath, previousPath]);

  if (alertType === 'alert') {
    return (
      <Alert classNames={['mt-12']} dismissible onDismiss={handleDismiss} severity={severity}>
        {message}
      </Alert>
    );
  } else if (alertType === 'snackbar') {
    return (
      <Snackbar
        classNames={['mt-10']}
        title={title}
        message={message}
        animationType="fade-in"
        autoHideDuration={10000}
        position="top-right"
        onDismiss={handleDismiss}
        severity={severity}
      />
    );
  }
};

export default BannerAlert;
